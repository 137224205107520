import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-choose-teddy-two',
  templateUrl: './choose-teddy-two.component.html',
  styleUrls: ['./choose-teddy-two.component.sass']
})
export class ChooseTeddyTwoComponent implements OnInit {
  customOptions: OwlOptions;
  activeSlides!: SlidesOutputData;

  constructor(private router: Router) {
    this.customOptions = {
      loop:true,
      margin:10,
      center: true,
      dots: false,
      nav: true,
      navText: [
        "<img src='/assets/portal/arrow-left.png' class='arrow-left' />",
        "<img src='/assets/portal/arrow-right.png' class='arrow-right' />"
      ],
      responsive: {
        0: { items:1 },
        600: { items:1 },
        1000: { items:1 }
      }
    }
  }

  ngOnInit(): void {
  }

  getData(data: SlidesOutputData) {}

  async goToLaunchpad() {
    await this.router.navigateByUrl('/portal/launchpad');
  }

}
