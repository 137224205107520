import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quadrant-one',
  templateUrl: './quadrant-one.component.html',
  styleUrls: ['./quadrant-one.component.sass']
})
export class QuadrantOneComponent implements OnInit {
  @Input() quadrant: string = '';
  @Input() viewing: string = '';
  @Output() selectPlanet = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}
