import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IMetadata } from 'src/app/interfaces/IMetadata';
import { ContractsService } from 'src/app/services/contracts.service';
import { IpfsService } from 'src/app/services/ipfs.service';
import { Web3Service } from 'src/app/services/web3.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.sass'],
})
export class TokenComponent implements OnInit {
  tokenData!: IMetadata;
  contractAddress!: string;
  sending: boolean = false;
  customizeEmail: string = '';
  customizeName: string = '';
  errorMessage: string = '';
  owned: boolean = false;
  sent: boolean = false;
  public loading: boolean = true;

  constructor(
    private ipfsService: IpfsService,
    private web3Service: Web3Service,
    private contractService: ContractsService,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(async (parameter) => {
      const contract = await this.contractService.getContract(environment.contractId);
      const selectedContract = new this.web3Service.web3.eth.Contract(
        JSON.parse(contract.Contract).abi as any,
        contract.Address
      );
      this.contractAddress = contract?.Address;
      const tokens = await selectedContract.methods.getTokenIds(this.web3Service.accounts[0]).call();
      for (let token of tokens) {
        if (token === parameter.tokenId) {
          this.owned = true;
          break;
        }
      }
      const token = await this.contractService.getToken(environment.contractId, parameter.tokenId);
      if (token) {
        this.loading = false;
      }
      const voucher = JSON.parse(token.Voucher);
      const uri = voucher.uri;
      const tokenResponse = await this.ipfsService.getMetadata(uri);
      tokenResponse.image = tokenResponse.image.replace(
        'ipfs://',
        'https://ipfs.mintedteddy.com/cdn-cgi/image/fit=scale-down,width=328/ipfs/'
      );
      tokenResponse.opensea = `${environment.openSeaUrl}/assets/${this.contractAddress}/${token.TokenId}`;
      tokenResponse.series = String(voucher.edition);
      this.tokenData = tokenResponse;
    });
  }

  async customizeTeddy() {
    this.sending = true;
    this.errorMessage = '';

    await this.web3Service.getAccounts();

    if (!(this.customizeEmail.trim() && this.customizeName.trim() && this.web3Service.selectedAccount)) {
      this.errorMessage = 'Please enter all the fields.';
      this.sending = false;
      return;
    }

    const contract = await this.contractService.getContract(environment.contractId);

    try {
      await this.contractService.customize(
        environment.contractId,
        this.tokenData.name,
        this.web3Service.selectedAccount,
        this.customizeName,
        this.customizeEmail
      );
      this.sent = true;
      this.errorMessage = 'Thank you!  We have received your request.  We will be in touch shortly.';
    } catch (ex: any) {
      this.errorMessage = ex.message;
    } finally {
      this.sending = false;
    }
  }
}
