import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { environment } from 'src/environments/environment';
import { estimateFees } from '@mycrypto/gas-estimation';
import { Web3Service } from 'src/app/services/web3.service';
import TeddiesAbi from '../../../assets/abis/teddiesAbi.json';
import BigNumber from 'bignumber.js';

@Component({
  selector: 'app-premint',
  templateUrl: './premint.component.html',
  styleUrls: ['./premint.component.sass'],
})
export class PreMintComponent implements OnInit {
  selectedVoucher: any;
  minting: boolean = false;
  loadingVoucher: boolean = true;
  numMinted: number = 0;
  maxSupply: number = 0;
  mintPrice: string = '0';
  remaining: number = 0;
  mintQty: number = 1;

  public processing: boolean = false;
  constructor(
    private router: Router,
    public web3Service: Web3Service,
    private _activatedRoute: ActivatedRoute,
    private contractService: ContractsService
  ) {}

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(async (parameter) => {
      this.numMinted = 0;
      this.maxSupply = 2500;

      const teddiesAbi = TeddiesAbi;
      const selectedContract = new this.web3Service.web3.eth.Contract(
        teddiesAbi.abi as any,
        environment.teddiesContract
      );

      this.numMinted = await selectedContract.methods.totalSupply().call();
      this.maxSupply = await selectedContract.methods.MAX_COLLECTION_SIZEZ().call();
      this.mintPrice = await selectedContract.methods.MINT_PRICE().call();
    });
  }

  ngAfterContentInit(): void {
    this.remaining = this.maxSupply - this.numMinted;
  }

  async preMint() {
    try {
      await this.web3Service.getAccounts();

      this.minting = true;
      const teddiesAbi = TeddiesAbi;
      const selectedContract = new this.web3Service.web3.eth.Contract(
        teddiesAbi.abi as any,
        environment.teddiesContract
      );
      const nonce = await this.web3Service.web3.eth.getTransactionCount(environment.teddiesContract, 'latest'); // get latest nonce
      const { maxFeePerGas, maxPriorityFeePerGas } = await estimateFees(environment.infuraUrl);
      try {
        const gasEstimate = await this.web3Service.web3.eth.estimateGas({
          from: this.web3Service.accounts[0],
          nonce: nonce,
          to: environment.teddiesContract,
          data: selectedContract.methods.preMint(1).encodeABI(),
          value: 0,
        });

        // Create the transaction
        const tx = {
          from: this.web3Service.accounts[0],
          to: environment.teddiesContract,
          nonce: nonce,
          gas: gasEstimate,
          maxFeePerGas: maxFeePerGas.toString(),
          value: 0,
          data: selectedContract.methods.preMint(1).encodeABI(),
        };

        await this.web3Service.web3.eth.sendTransaction(tx);
        this.router.navigateByUrl('/mint/success');
      } catch (ex: any) {
        alert(ex.message);
      }
    } catch (ex: any) {
      alert(ex.message);
    }

    this.minting = false;
  }

  async connectWallet() {
    await this.web3Service.connectWallet();
  }

  updateQty(str: string) {
    if (str === 'add') {
      if (this.mintQty < this.remaining) {
        this.mintQty = this.mintQty + 1;
      }
    }
    if (str === 'minus') {
      if (this.mintQty > 1) {
        this.mintQty = this.mintQty - 1;
      }
    }
  }
}
