import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IContract } from '../interfaces/IContract';
import { IVoucher } from '../interfaces/IVoucher';
import { IVoucherOption } from '../interfaces/IVoucherOption';
import { IReservationInvitation } from '../interfaces/IReservationInvitation';
import { IPreviewTokenMetadata, ITokenMetadata } from '../interfaces/ITokenMetadata';

@Injectable({
  providedIn: 'root',
})
export class NgService {
  constructor(private http: HttpClient) {}

  async checkClaimableTeddy(teddyId: string) {
    return false;
  }
}
