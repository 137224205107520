<div id="ChooseTeddyComponent" class="portal-app">
  <img src="/assets/portal/header-text-2.png" class="heading-text" />

  <div id="carousel-container">
    <owl-carousel-o (change)="getData($event)" [options]="customOptions" #owlCarousel>
      <ng-container>
        <ng-template carouselSlide [id]="'1'">
          <img src="/assets/portal/change-teddy/card-1.png" (click)="goToTeddyChosen()" />
        </ng-template>

        <ng-template carouselSlide [id]="'2'">
          <img src="/assets/portal/change-teddy/card-2.png" />
        </ng-template>

        <ng-template carouselSlide [id]="'3'">
          <img src="/assets/portal/change-teddy/card-3.png" />
        </ng-template>

      </ng-container>
    </owl-carousel-o>
  </div>

  <iframe src="https://player.vimeo.com/video/782825795?autoplay=1&loop=1&autopause=0&muted=1&background=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

  <button class="secondary">Certificate of Authenticity</button>

  <script src="https://player.vimeo.com/api/player.js"></script>

</div>
