<header-component></header-component>

<div id="teddyCheckerComponent">
  <div *ngIf="processing" class="loading-container">
    <div class="spinner-border" role="status">
      <span class="sr-only">Processing...</span>
    </div>
  </div>

  <div *ngIf="!processing" class="container px-4">
    <div class="row">
      <div class="col-12">
        <h1 class="mt-5 mb-4">Welcome, Series 1 Teddy Holders</h1>
        <h3 class="mb-4">Use this form to see if your Explorer Teddy is available.</h3>
        <div class="d-flex">
          <input [(ngModel)]="teddyId" placeholder="Enter ID" />
          <button class="secondary" (click)="checkTeddy()">Check Now 🔎 🧸</button>
        </div>
      </div>
    </div>
  </div>
</div>

<footer-component></footer-component>
