<header-component></header-component>
<div id="TeddyExplorerVideo" class="container">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-12 col-md-10 py-5">
      <h1 class="text-center mb-3">Teddy Explorer</h1>
      <iframe
        src="https://player.vimeo.com/video/830496230?h=183e1c9c8b?autoplay=0&loop=0&autopause=0&muted=0&background=0"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  </div>
</div>
<footer-component></footer-component>
