<header-component></header-component>

<div id="redeemComponent">
  <div class="hero-banner">
    <div class="row">
      <div class="col-12">
        <div *ngIf="loadingVoucher" class="loading">
          <div class="spinner-border text-light" role="status"></div>
          <h2>Loading...</h2>
        </div>
        <h2 *ngIf="!loadingVoucher">Mint Your Teddy</h2>
      </div>
    </div>
  </div>
  <div *ngIf="!loadingVoucher" class="container pt-4 pt-sm-5 pb-5">
    <div class="row">
      <div class="col-12">
        <div class="content-container">
          <!-- Not Logged In -->
          <div *ngIf="!(web3Service.accounts && web3Service.accounts.length > 0)" class="content">
            <p>To mint your teddy, you must first connect your wallet.</p>
            <p><button [disabled]="minting || loadingVoucher" (click)="connectWallet()">Connect Wallet</button></p></div>
          </div>
          <!-- Logged In -->
          <div *ngIf="web3Service.accounts && web3Service.accounts.length > 0" class="content">
            <span class="highlight">By Invitation Only</span>
            <div class="reserve-form">
              <div *ngIf="!loadingVoucher && !selectedVoucher">You don't have a reservation.</div>
              <div>
                <div *ngIf="web3Service.accounts && web3Service.accounts.length > 0 && selectedVoucher"><p><button [disabled]="minting || loadingVoucher" (click)="mintVoucher()">Mint Teddy</button></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer-component></footer-component>
<!--
<div *ngIf="!loadingVoucher && !selectedVoucher">No teddies available.</div>
<div *ngIf="selectedVoucher">
    <div *ngIf="!(web3Service.accounts && web3Service.accounts.length > 0)">
        <p>To mint a teddy, you must first connect your wallet</p>
        <p><button [disabled]="minting || loadingVoucher" (click)="connectWallet()">Connect Wallet</button></p></div>
    <div *ngIf="web3Service.accounts && web3Service.accounts.length > 0"><p><button [disabled]="minting || loadingVoucher" (click)="mintVoucher()">Mint Teddy</button></p>
    </div>
</div>
-->