import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IMetadata } from 'src/app/interfaces/IMetadata';
import { ContractsService } from 'src/app/services/contracts.service';
import { IpfsService } from 'src/app/services/ipfs.service';
import { Web3Service } from 'src/app/services/web3.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog-detail.component.html',
  styleUrls: ['./catalog-detail.component.sass']
})
export class CatalogDetailComponent implements OnInit {
  tokenData!: IMetadata;
  contractAddress!: string;
  sending: boolean = false;
  owned: boolean = false;
  public loading: boolean = true;

  constructor(private ipfsService: IpfsService, private web3Service: Web3Service, private contractService: ContractsService, private _activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(async parameter => {
      const contract = await this.contractService.getContract(environment.contractId);
      const selectedContract = new this.web3Service.web3.eth.Contract(JSON.parse(contract.Contract).abi as any, contract.Address);
      this.contractAddress = contract?.Address;
      const tokens = await selectedContract.methods.getTokenIds(this.web3Service.accounts[0]).call();
      for (let token of tokens) {
        if (token === parameter.tokenId) {
          this.owned = true;
          break;
        }
      }
      const token = await this.contractService.getToken(environment.contractId, parameter.tokenId);
      if (token) {
        this.loading = false;
      }
      const voucher = JSON.parse(token.Voucher);
      const uri = voucher.uri;
      const tokenResponse = await this.ipfsService.getMetadata(uri);
      tokenResponse.image = tokenResponse.image.replace('ipfs://', 'https://ipfs.mintedteddy.com/cdn-cgi/image/fit=scale-down,width=328/ipfs/')
      tokenResponse.opensea = `${environment.openSeaUrl}/assets/${this.contractAddress}/${token.TokenId}`;
      tokenResponse.series = String(voucher.edition);
      this.tokenData = tokenResponse;
    })
  }

  goNext() {
    window.location.href = "/catalog/" + (Number(this.tokenData.name.split('#')[1]) + 1).toString();
  }

  goPrev() {
    window.location.href = "/catalog/" + (Number(this.tokenData.name.split('#')[1]) > 1 ? Number(this.tokenData.name.split('#')[1]) - 1 : 1).toString();
  }
}
