<header-component></header-component>

<div id="comingSoonComponent">
  <h1 class="visually-hidden">Coming Soon</h1>
  <div class="minted-bg">
    <h1 class="font-300 text-uppercase font-400 color-gradient-pale font-opacity">Minted Teddy</h1>
  </div>
  <div class="container px-4">
    <div class="row">
      <div class="col-12">
        <h1 class="mt-5 mb-4">Coming Soon</h1>
        <h2 class="reversed">This feature is not ready yet. Please check back later 🙏</h2>
      </div>
    </div>
  </div>
</div>

<footer-component></footer-component>
