import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-inline-video',
  templateUrl: './inline-video.component.html',
  styleUrls: ['./inline-video.component.sass']
})
export class InlineVideoComponent implements OnInit {
  @Input() showing: string = '';
  videoPlayback: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  toggleVideoPlayback() {
    console.log(this.videoPlayback)
    // if (this.videoPlayback === true) {
    //   this.videoPlayback = false;
    // } else {
    //   this.videoPlayback = true;
    // }
    this.videoPlayback = !this.videoPlayback;
  }
}
