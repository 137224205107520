import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-quadrant-five-panels',
  templateUrl: './quadrant-five-panels.component.html',
  styleUrls: ['./quadrant-five-panels.component.sass'],
})
export class QuadrantFivePanelsComponent implements OnInit {
  @Input() showing = '';
  @Input() transition = '';
  @Output() closeDetails = new EventEmitter<string>();
  @Output() showVideo = new EventEmitter<string>();
  @Output() showPopup = new EventEmitter<string>();
  video: string = '';
  birthdayPlanetSubmitted: boolean = false;
  birthdayPlanetNotifyEmail: string = '';
  toysPlanetSubmitted: boolean = false;
  toysPlanetNotifyEmail: string = '';
  fashionPlanetSubmitted: boolean = false;
  fashionPlanetNotifyEmail: string = '';
  musicPlanetSubmitted: boolean = false;
  musicPlanetNotifyEmail: string = '';
  constructor(private router: Router, private notificationService: NotificationService) {}

  ngOnInit(): void {

  }

  ngAfterViewChecked() {
    const musicInput = document.getElementById("musicPlanetInput")
    const birthdayInput = document.getElementById("birthdayPlanetInput")
    const toysInput = document.getElementById("toysPlanetInput")
    const fashionInput = document.getElementById("fashionPlanetInput")
    if (musicInput !== null) {
      musicInput.addEventListener("mousedown", this.focusInput, true);
    }
    if (birthdayInput !== null) {
      birthdayInput.addEventListener("mousedown", this.focusInput, true);
    }
    if (toysInput !== null) {
      toysInput.addEventListener("mousedown", this.focusInput, true);
    }
    if (fashionInput !== null) {
      fashionInput.addEventListener("mousedown", this.focusInput, true);
    }
  	// musicInput.addEventListener("mousemove", this.focusInput, true);
  	// musicInput.addEventListener("mouseup", this.focusInput, true);
  	// musicInput.addEventListener("mousecancel", this.focusInput, true);
  }

  async goToCatalog() {
    await this.router.navigateByUrl('/catalog');
  }

  async goToOGSeriesCatalog() {
    await this.router.navigateByUrl('/catalog/og-series');
  }

  async goToSeries1Catalog() {
    await this.router.navigateByUrl('/catalog/series1');
  }

  async goToNextGenPreview() {
    await this.router.navigateByUrl('/catalog/nextgen');
  }

  async goToTheodoruPreview() {
    await this.router.navigateByUrl('/catalog/theodoru');
  }

  async goToMoonCatsPreview() {
    await this.router.navigateByUrl('/catalog/mooncats');
  }

  async goToBrendaChenPreview() {
    await this.router.navigateByUrl('/catalog/brendachen');
  }

  async goToWilliamWilloughbyPreview() {
    await this.router.navigateByUrl('/catalog/williamwilloughbyiii');
  }

  async goToStore() {
    window.open('https://shop.mintedteddy.com', '_parent');
  }

  async gotoOGCouncil() {
    alert('Coming soon...');
  }

  async birthdayPlanetNotify() {
    this.birthdayPlanetSubmitted = true;
    await this.notificationService.notifyBirthdayPlanet(this.birthdayPlanetNotifyEmail);
  }

  async fashionPlanetNotify() {
    this.fashionPlanetSubmitted = true;
    await this.notificationService.notifyFashionPlanet(this.birthdayPlanetNotifyEmail);
  }

  async musicPlanetNotify() {
    this.musicPlanetSubmitted = true;
    await this.notificationService.notifyMusicPlanet(this.birthdayPlanetNotifyEmail);
  }

  async toysPlanetNotify() {
    this.toysPlanetSubmitted = true;
    await this.notificationService.notifyToysPlanet(this.birthdayPlanetNotifyEmail);
  }

  focusInput(e: any) {
    e.preventDefault();
    if (e !== null && e.target !== null) {
      e.target.focus()
    }
  }
}
