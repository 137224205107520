import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-planet-guardians-invite',
  templateUrl: './planet-guardians-invite.component.html',
  styleUrls: ['./planet-guardians-invite.component.sass'],
})
export class PlanetGuardiansInviteComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  async goToMint() {
    await this.router.navigateByUrl('/mintplanetinvite');
  }
}
