import { Component, OnInit } from '@angular/core';
import { NgService } from 'src/app/services/ng.service';
import { Web3Service } from 'src/app/services/web3.service';
import { environment } from 'src/environments/environment';
import TeddiesAbi from '../../../assets/abis/teddiesAbi.json';

@Component({
  selector: 'app-teddy-checker',
  templateUrl: './teddy-checker.component.html',
  styleUrls: ['./teddy-checker.component.sass'],
})
export class TeddyCheckerComponent implements OnInit {
  public processing: boolean = false;
  public teddyId: string = '';

  constructor(private ngService: NgService, private web3Service: Web3Service) {}

  ngOnInit(): void {}

  async checkTeddy() {
    if (Number(this.teddyId) > 100 && Number(this.teddyId) < 1100) {
      const teddiesAbi = TeddiesAbi;
      const selectedContract = new this.web3Service.web3.eth.Contract(
        teddiesAbi.abi as any,
        environment.teddiesContract
      );

      const claimed = await selectedContract.methods.getPreMintStatus(this.teddyId).call();
      console.log(claimed);
      if (claimed) {
        alert('preminting not available');
      } else {
        alert('preminting is available for this teddy');
      }
    } else {
      alert('this is not a series 1 teddy.');
    }
  }
}
