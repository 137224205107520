<div class="backdrop" [ngClass]="{ 'super-zoom': viewing === 'quadrant-1' }"></div>
<div class="planets" [ngClass]="{ 'super-zoom': viewing === 'quadrant-1' }">
  <h1 class="visually-hidden">Mintedverse Qudrant 1</h1>
  <div
    class="planet blue-planet blue-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Claubena"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet pink-planet pink-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Melmira"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet yellow-planet yellow-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Stelobera"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Samsimea"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet blue-planet blue-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Sunalwao"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet pink-planet pink-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Raikidoa"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet yellow-planet yellow-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Tobuaua"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Krycoma"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet blue-planet blue-planet-3"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Mintedora"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-3"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Tocoova"
    aria-label="Theme Planet"
  ></div>
</div>
<div class="overlay"></div>
