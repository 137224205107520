<div id="TeddyGallery" class="px-2 px-sm-5 py-lg-4">
  <h1 class="visually-hidden">Mooncats X Minted Teddy</h1>
  <div class="container-fluid p-0">
    <mat-grid-list [cols]="cols" rowHeight="1:1" gutterSize="10px">
      <mat-grid-tile *ngFor="let item of catalogData" rowspan="1">
        <a (click)="seeDetails(teddycontent, item)">
          <img [src]="item.metadata.image" [alt]="item.metadata.name" />
        </a>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

<ng-template #teddycontent let-modal>
  <div class="modal-body selected-teddy p-0">
    <div *ngIf="selectedTeddy">
      <video [src]="selectedMovie" autoplay="true" loop="true" (click)="closeModal()"></video>
      <div class="item-details p-sm-4">
        <div class="px-sm-4 px-3 pt-5 pt-md-4">
          <h1 class="color-gradient-pale">{{ selectedTeddy.metadata.name }}</h1>
          <!--<p class="color-pale-yellow">
            Wherever you go, no matter what the weather, always bring your own sunshine
          </p>-->
        </div>
        <!-- List Properties -->
        <div class="teddy-properties px-sm-4 px-3 my-4">
          <div class="row">
            <div class="col-12 py-2">
              <h3 class="color-pale-yellow m-0">PROPERTIES</h3>
            </div>
          </div>
          <div class="row m-0 p-0 position-relative">
            <div class="col-12 col-sm-9 col-md-12">
              <div class="row">
                <div
                  *ngFor="let property of selectedTeddy.metadata.attributes"
                  class="col-6 col-sm-3 col-md-6 col-lg-4 p-0 mt-sm-4 mt-3"
                >
                  <h3 class="color-pale-yellow mb-0">{{ property['trait_type'] }}</h3>
                  <label>{{ property.value }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Buttons -->
        <div class="close-btn py-3 px-4 reversed mt-3 mb-5" (click)="closeModal()">Close</div>
      </div>
    </div>
    <div *ngIf="!selectedTeddy">
      <div class="p-5 reversed">Sorry, no Teddy with this id found.</div>
    </div>
  </div>
</ng-template>
