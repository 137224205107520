<header-component></header-component>

<!-- Navigation -->
<div class="nav-info d-flex align-items-center justify-content-center py-3 px-3">
  <h1 class="visually-hidden">Mintedverse</h1>
  <p class="m-0 reversed">Drag to explore the Mintedverse. Double click/tap to zoom into a planet cluster! 🪐🪐🪐</p>
</div>

<!-- Mintedverse -->
<div id="mintedverseComponent" class="container-fluid root" [ngClass]="display">
  <div class="point">
    <div class="canvas">
      <div class="space-container">
        <div class="space-quadrants" [ngClass]="{ 'show-quadrants': delay === false, active: quadrant !== '' }">
          <!-- Quadrant 1 -->
          <app-quadrant-one
            id="quadrant-1"
            (mouseenter)="mouseEnter('quadrant-1')"
            (mouseleave)="mouseLeave('quadrant-1')"
            [quadrant]="quadrant"
            [viewing]="viewing"
            [ngClass]="{ selected: quadrant === 'quadrant-1' }"
            appDoubleClick
            (doubleClick)="goToQuadrant1()"
            (tap)="goToQuadrant1()"
          >
          </app-quadrant-one>
          <!-- Quadrant 2 -->
          <app-quadrant-two
            id="quadrant-2"
            (mouseenter)="mouseEnter('quadrant-2')"
            (mouseleave)="mouseLeave('quadrant-2')"
            [quadrant]="quadrant"
            [viewing]="viewing"
            [ngClass]="{ selected: quadrant === 'quadrant-2' }"
            appDoubleClick
            (doubleClick)="goToQuadrant2()"
            (tap)="goToQuadrant2()"
          >
          </app-quadrant-two>
          <!-- Quadrant 3 -->
          <app-quadrant-three
            id="quadrant-3"
            (mouseenter)="mouseEnter('quadrant-3')"
            (mouseleave)="mouseLeave('quadrant-3')"
            [quadrant]="quadrant"
            [viewing]="viewing"
            [ngClass]="{ selected: quadrant === 'quadrant-3' }"
            appDoubleClick
            (doubleClick)="goToQuadrant3()"
            (tap)="goToQuadrant3()"
          >
          </app-quadrant-three>
          <!-- Quadrant 4 -->
          <app-quadrant-four
            id="quadrant-4"
            (mouseenter)="mouseEnter('quadrant-4')"
            (mouseleave)="mouseLeave('quadrant-4')"
            [quadrant]="quadrant"
            [viewing]="viewing"
            [ngClass]="{ selected: quadrant === 'quadrant-4' }"
            appDoubleClick
            (doubleClick)="goToQuadrant4()"
            (tap)="goToQuadrant4()"
          >
          </app-quadrant-four>
          <!-- Quadrant 5 -->
          <app-quadrant-five
            id="quadrant-5"
            (mouseenter)="mouseEnter('quadrant-5')"
            (mouseleave)="mouseLeave('quadrant-5')"
            [quadrant]="quadrant"
            [viewing]="viewing"
            [ngClass]="{ selected: quadrant === 'quadrant-5' }"
            appDoubleClick
            (doubleClick)="goToQuadrant5()"
            (tap)="goToQuadrant5()"
          >
          </app-quadrant-five>
          <!-- Quadrant 6 -->
          <app-quadrant-six
            id="quadrant-6"
            (mouseenter)="mouseEnter('quadrant-6')"
            (mouseleave)="mouseLeave('quadrant-6')"
            [quadrant]="quadrant"
            [viewing]="viewing"
            [ngClass]="{ selected: quadrant === 'quadrant-6' }"
            appDoubleClick
            (doubleClick)="goToQuadrant6()"
            (tap)="goToQuadrant6()"
          >
          </app-quadrant-six>
        </div>
        <div class="sun-wrapper">
          <div
            *ngIf="viewing === ''"
            class="mintedverse-sun"
            appDoubleClick
            (doubleClick)="showTreasury()"
            (tap)="showTreasury()"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Small Screen Disclaimer -->
<div class="display-disclaimer py-4 px-3">
  <p class="reversed">The Mintedverse is best experienced on a larger screen. 🚀</p>
</div>

<!-- Sun Dialog Popup -->
<app-popup-dialog-box id="mintedversePopup" *ngIf="popup !== ''" [popupContent]="popup" (hidePopup)="hidePopup()">
</app-popup-dialog-box>
