import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-planet-guardians',
  templateUrl: './planet-guardians.component.html',
  styleUrls: ['./planet-guardians.component.sass'],
})
export class PlanetGuardiansComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  async goToMint() {
    await this.router.navigateByUrl('/mint');
  }
}
