import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';
import { create } from 'pinch-zoom-pan';

@Component({
  selector: 'app-mintedverse',
  templateUrl: './mintedverse.component.html',
  styleUrls: ['./mintedverse.component.sass']
})
export class MintedverseComponent implements OnInit {
  public quadrant: string = '';
  public viewing: string = '';
  delay: boolean = true;
  popup: string = '';
  display: string = 'lg';
  // media query resize columns shown
  private mediaSubscription: any;
  private activeMediaQuery = '';

  constructor(private router: Router, private mediaObserver: MediaObserver,) { }

  ngOnInit(): void {
    // Detect screen width
    this.mediaSubscription = this.mediaObserver.asObservable().subscribe((change) => {
      change.forEach((item) => {
        this.activeMediaQuery = item ? `'${item.mqAlias}' = (${item.mediaQuery})` : '';
        if (item.mqAlias === 'xs') {
          this.display = 'xs';
        }
        if (item.mqAlias === 'sm') {
          this.display = 'sm';
        }
        if (item.mqAlias === 'md') {
          this.display = 'md';
        }
        if (item.mqAlias === 'lg') {
          this.display = 'lg';
        }
      });
    });

    const mintedverseEl = document.getElementById('mintedverseComponent');
    if (mintedverseEl !== null) {
      create({ element: mintedverseEl });
    }
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.delay = false;
    }, 1000);
  }

  mouseEnter(div: string) {
    this.quadrant = div;
  }

  mouseLeave(div: string) {
    this.quadrant = '';
  }

  showTreasury() {
    this.popup = 'treasury-star';
  }

  hidePopup() {
    this.popup = '';
  }

  goToQuadrant1() {
    this.viewing = 'quadrant-1';
    setTimeout(() => {
      this.router.navigateByUrl('/mintedverse/quadrant-3');
    }, 1000);
  }

  goToQuadrant2() {
    this.viewing = 'quadrant-2';
    setTimeout(() => {
      this.router.navigateByUrl('/mintedverse/quadrant-4');
    }, 1000);
  }

  goToQuadrant3() {
    this.viewing = 'quadrant-3';
    setTimeout(() => {
      this.router.navigateByUrl('/mintedverse/quadrant-5');
    }, 1000);
  }

  goToQuadrant4() {
    this.viewing = 'quadrant-4';
    setTimeout(() => {
      this.router.navigateByUrl('/mintedverse/quadrant-2');
    }, 1000);
  }

  goToQuadrant5() {
    this.viewing = 'quadrant-5';
    setTimeout(() => {
      this.router.navigateByUrl('/mintedverse/quadrant-1');
    }, 1000);
  }

  goToQuadrant6() {
    this.viewing = 'quadrant-6';
    setTimeout(() => {
      this.router.navigateByUrl('/mintedverse/quadrant-6');
    }, 1000);
  }

  ngOnDestroy(): void {
    this.mediaSubscription.unsubscribe();
  }
}
