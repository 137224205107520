<div class="backdrop" [ngClass]="{ 'super-zoom': viewing === 'quadrant-2' }"></div>
<div class="planets" [ngClass]="{ 'super-zoom': viewing === 'quadrant-2' }">
  <h1 class="visually-hidden">Mintedverse Qudrant 2</h1>
  <div
    class="planet blue-planet blue-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Moonalozaka"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet pink-planet pink-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Cosmikawa"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet yellow-planet yellow-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Vancanaheha"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Usanerusa"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet blue-planet blue-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Vitaletha"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet pink-planet pink-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Ethawagmeta"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet yellow-planet yellow-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Rogeraxa"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Memapostaba"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet blue-planet blue-planet-3"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Victaloona"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet pink-planet pink-planet-3"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Fikalazea"
    aria-label="Theme Planet"
  ></div>
</div>
<div class="overlay"></div>
