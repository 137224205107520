import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './pages/home/home.component';
import { RedeemComponent } from './pages/redeem/redeem.component';
import { ConnectComponent } from './pages/connect/connect.component';
import { ClubComponent } from './pages/club/club.component';
import { AccountComponent } from './pages/account/account.component';
import { MintComponent } from './pages/mint/mint.component';
import { MintPlanetComponent } from './pages/mint-planet/mint-planet.component';
import { MintPlanetInviteComponent } from './pages/mint-planet-invite/mint-planet-invite.component';
import { TokenComponent } from './pages/token/token.component';
import { GetComponent } from './pages/get/get.component';
import { NoaccessComponent } from './pages/noaccess/noaccess.component';
import { ReserveComponent } from './pages/reserve/reserve.component';
import { InvitationComponent } from './pages/invitation/invitation.component';
import { CatalogComponent } from './pages/catalog/catalog.component';
import { MyTeddiesComponent } from './pages/my-teddies/my-teddies.component';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { TeddyGalleryComponent } from './components/teddy-gallery/teddy-gallery.component';
import { TeddyGalleryMoonCatsComponent } from './components/teddy-gallery-mooncats/teddy-gallery-mooncats.component';
import { TeddyGalleryBrendaChenComponent } from './components/teddy-gallery-brendachen/teddy-gallery-brendachen.component';
import { TeddyGalleryTheodoruComponent } from './components/teddy-gallery-theodoru/teddy-gallery-theodoru.component';
import { DropScheduleComponent } from './pages/drop-schedule/drop-schedule.component';
import { OpenseaBtnComponent } from './components/opensea-btn/opensea-btn.component';
import { RoadmapAccordionComponent } from './components/roadmap-accordion/roadmap-accordion.component';
import { QuadrantComponent } from './pages/quadrant/quadrant.component';
import { MintedverseComponent } from './pages/mintedverse/mintedverse.component';
import { TeddyGalleryNextGenComponent } from './components/teddy-gallery-nextgen/teddy-gallery-nextgen.component';
import { TeddyGallerySeries1Component } from './components/teddy-gallery-series1/teddy-gallery-series1.component';
import { TeddyGalleryWilliamComponent } from './components/teddy-gallery-william/teddy-gallery-william.component';
import { ShowVideoComponent } from './components/show-video/show-video.component';
import { QuadrantSixComponent } from './components/mintedverse/quadrant-six/quadrant-six.component';
import { QuadrantSixPanelsComponent } from './components/mintedverse/quadrant-six-panels/quadrant-six-panels.component';
import { QuadrantFiveComponent } from './components/mintedverse/quadrant-five/quadrant-five.component';
import { QuadrantFivePanelsComponent } from './components/mintedverse/quadrant-five-panels/quadrant-five-panels.component';
import { QuadrantFourComponent } from './components/mintedverse/quadrant-four/quadrant-four.component';
import { QuadrantFourPanelsComponent } from './components/mintedverse/quadrant-four-panels/quadrant-four-panels.component';
import { QuadrantThreeComponent } from './components/mintedverse/quadrant-three/quadrant-three.component';
import { QuadrantThreePanelsComponent } from './components/mintedverse/quadrant-three-panels/quadrant-three-panels.component';
import { QuadrantTwoComponent } from './components/mintedverse/quadrant-two/quadrant-two.component';
import { QuadrantTwoPanelsComponent } from './components/mintedverse/quadrant-two-panels/quadrant-two-panels.component';
import { QuadrantOneComponent } from './components/mintedverse/quadrant-one/quadrant-one.component';
import { QuadrantOnePanelsComponent } from './components/mintedverse/quadrant-one-panels/quadrant-one-panels.component';
import { PhysicalToyInfoComponent } from './components/physical-toy-info/physical-toy-info.component';
import { GenericPlanetContentComponent } from './components/mintedverse/generic-planet-content/generic-planet-content.component';
import { TeddyCheckerComponent } from './pages/teddy-checker/teddy-checker.component';
import { PreMintComponent } from './pages/premint/premint.component';
import { PopupDialogBoxComponent } from './components/popup-dialog-box/popup-dialog-box.component';
import { TeddyGalleryTestComponent } from './components/teddy-gallery-test/teddy-gallery-test.component';
import { DoubleClickDirective } from './directives/double-click.directive';
import { HammerModule } from '../../node_modules/@angular/platform-browser';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { HomeV2Component } from './experimental/home-v2/home-v2.component';
import { InlineVideoComponent } from './components/inline-video/inline-video.component';
import { LandingComponent } from './portal/landing/landing.component';
import { ChooseTeddyComponent } from './portal/choose-teddy/choose-teddy.component';
import { AvatarViewComponent } from './portal/avatar-view/avatar-view.component';
import { LaunchpadComponent } from './portal/launchpad/launchpad.component';
import { ChooseTeddyTwoComponent } from './portal/choose-teddy-two/choose-teddy-two.component';
import { TeddyExplorersComponent } from './pages/teddy-explorers/teddy-explorers.component';
import { PlanetGuardiansComponent } from './pages/planet-guardians/planet-guardians.component';
import { PlanetGuardiansInviteComponent } from './pages/planet-guardians-invite/planet-guardians-invite.component';
import { FaqComponent } from './pages/faq/faq.component';
import { VideoBirthdayTeddyComponent } from './pages/videos/video-birthday-teddy/video-birthday-teddy.component';
import { VideoTeddyExplorerComponent } from './pages/videos/video-teddy-explorer/video-teddy-explorer.component';
import { PromoVideosComponent } from './pages/promo-videos/promo-videos.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RedeemComponent,
    ConnectComponent,
    ClubComponent,
    AccountComponent,
    MintComponent,
    MintPlanetComponent,
    MintPlanetInviteComponent,
    PreMintComponent,
    TokenComponent,
    GetComponent,
    NoaccessComponent,
    HeaderComponent,
    FooterComponent,
    ReserveComponent,
    InvitationComponent,
    CatalogComponent,
    MyTeddiesComponent,
    TeddyGalleryComponent,
    TeddyGalleryMoonCatsComponent,
    TeddyGalleryBrendaChenComponent,
    TeddyGalleryTheodoruComponent,
    TeddyGalleryNextGenComponent,
    TeddyGallerySeries1Component,
    TeddyGalleryWilliamComponent,
    DropScheduleComponent,
    OpenseaBtnComponent,
    RoadmapAccordionComponent,
    QuadrantFiveComponent,
    QuadrantComponent,
    MintedverseComponent,
    QuadrantFivePanelsComponent,
    ShowVideoComponent,
    QuadrantFourComponent,
    QuadrantFourPanelsComponent,
    QuadrantSixComponent,
    QuadrantSixPanelsComponent,
    QuadrantThreeComponent,
    QuadrantThreePanelsComponent,
    QuadrantTwoComponent,
    QuadrantTwoPanelsComponent,
    QuadrantOneComponent,
    QuadrantOnePanelsComponent,
    PhysicalToyInfoComponent,
    GenericPlanetContentComponent,
    TeddyCheckerComponent,
    PopupDialogBoxComponent,
    TeddyGalleryTestComponent,
    DoubleClickDirective,
    ComingSoonComponent,
    HomeV2Component,
    InlineVideoComponent,
    LandingComponent,
    ChooseTeddyComponent,
    AvatarViewComponent,
    LaunchpadComponent,
    ChooseTeddyTwoComponent,
    TeddyExplorersComponent,
    PlanetGuardiansComponent,
    PlanetGuardiansInviteComponent,
    FaqComponent,
    VideoBirthdayTeddyComponent,
    VideoTeddyExplorerComponent,
    PromoVideosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    CarouselModule,
    MatGridListModule,
    MatTooltipModule,
    FlexLayoutModule,
    HammerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [],
  providers: [Meta],
  bootstrap: [AppComponent],
})
export class AppModule {}
