import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-birthday-teddy',
  templateUrl: './video-birthday-teddy.component.html',
  styleUrls: ['./video-birthday-teddy.component.sass']
})
export class VideoBirthdayTeddyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
