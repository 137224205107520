<header-component></header-component>

<div id="accountComponent" class="container-fluid p-0">
  <div class="hero-banner" [ngClass]="{teddiesLoaded: tokens.length > 0 }">
    <div class="row">
      <div class="col-12">
        <div *ngIf="loading" class="loading">
          <div class="spinner-border text-light" role="status"></div>
          <h2>Loading...</h2>
        </div>
        <h2 *ngIf="!loading && tokens.length == 0">No Teddies Yet</h2>
        <h2 *ngIf="tokens.length > 0" class="mt-3">My NFTs</h2>
      </div>
    </div>
  </div>
  <!-- Teddy NFTs -->
  <div class="container mt-5">
    <div class="row">
      <div class="col-12 nfts-wrapper">
        <!-- Tokens -->
        <div *ngIf="!loading && tokens" class="mb-5 nft-container">
          <div *ngFor="let token of tokens">
            <div class="nft">
              <a href={{token.metaData.token_link}}>
                <div class="image" style="background-image: url({{token.metaData.image_link}})"></div>
              </a>
              <a href={{token.metaData.token_link}}>
                <div class="mt-2 mb-1 bold name">
                  {{token.metaData.name}}
                </div>
              </a>
              <div>{{token.metaData.description}}</div>
            </div>
          </div>
        </div>

        <p *ngIf="!loading && tokens.length == 0" class="pb-5 reversed">
          Your teddy NFTs will appear here shortly after you mint them. Visit the <a (click)="goHome()">Drop Schedule</a> to see if teddies are available to mint right now.
        </p>

      </div>
    </div>
  </div>
</div>

<footer-component></footer-component>
