<div id="AvatarViewComponent" class="portal-app">
  <div class="content-container">
    <img src="/assets/portal/header-text-1.png" class="heading-text" />

    <iframe src="https://player.vimeo.com/video/782825806?autoplay=1&loop=1&autopause=0&muted=1&background=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
  </div>

  <div class="footer">
    <button class="secondary" (click)="goToLaunchpad()">Launchpad 🚀</button>
  </div>

  <script src="https://player.vimeo.com/api/player.js"></script>

</div>
