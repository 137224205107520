import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CarouselModule, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { IMetadata } from 'src/app/interfaces/IMetadata';
import { ContractsService } from 'src/app/services/contracts.service';
import { IpfsService } from 'src/app/services/ipfs.service';
import { Web3Service } from 'src/app/services/web3.service';
import { environment } from 'src/environments/environment';
import TeddyTokens from '../../../assets/data/tokens.json';

@Component({
  selector: 'app-my-teddies',
  templateUrl: './my-teddies.component.html',
  styleUrls: ['./my-teddies.component.sass']
})
export class MyTeddiesComponent implements OnInit {
  myTeddiesData: any = [];
  contractAddress!: string;
  sending: boolean = false;
  owned: boolean = false;
  public loading: boolean = true;
  customOptions: OwlOptions;
  activeSlides!: SlidesOutputData;

  constructor(private ipfsService: IpfsService, private web3Service: Web3Service, private contractService: ContractsService, private router: Router, private _activatedRoute: ActivatedRoute) {
    this.customOptions = {
      loop:true,
      margin:10,
      center: true,
      dots: false,
      nav: true,
      navText: [
        "<div></div>",
        "<div></div>"
      ],
      responsive: {
        0: { items:1 },
        600: { items:1 },
        1000: { items:2 }
      }
    }
  }

  async getTeddy(selectedContract: any, tokenId: string) {
    const url = await selectedContract.methods.tokenURI(tokenId).call();
    try {
      this.ipfsService.getMetadata(url);

      const metaData = await this.ipfsService.getMetadata(url);
      // // token.id
      metaData.id = metaData.external_url ? metaData.external_url.split('/')[metaData.external_url.split('/').length-1] : '0';

      // token.detailUrl
      metaData.detailUrl = environment.siteUrl + '/token/' + tokenId;

      // token.metadata.name
      // token.metadata.image
      // token.metadata.attributes
      metaData.metadata = {
        name: metaData.name,
        image: metaData.image.replace('ipfs://', 'https://ipfs.mintedteddy.com/cdn-cgi/image/fit=scale-down,width=328/ipfs/'),
        attributes: metaData.attributes
      };

      metaData.openSeaUrl = 'https://opensea.io/assets/0x69A04d373e3E2Db13027E9d584eB8203d46a86C7/' + metaData.id;

      this.myTeddiesData.push(metaData);
      this.loading = false;
    } catch (ex) {
      console.log('invalid metadata');
    }

  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    const contract = await this.contractService.getContract(environment.contractId);
    const selectedContract = new this.web3Service.web3.eth.Contract(JSON.parse(contract.Contract).abi as any, contract.Address);

    if (this.web3Service.accounts.length > 0) {
      // Tokens
      const requests = [];

      try {
        const tokens = await selectedContract.methods.getTokenIds(this.web3Service.accounts[0]).call();
        if (tokens && tokens.length > 0) {
          for (const token of tokens) {
            requests.push(this.getTeddy(selectedContract, token));
          }
        }
      } catch (ex) {

      }
      Promise.all(requests);
    }

    // Keyboard events to toggle owl-carousel teddies
    document.onkeydown = event => {
      if (event.keyCode == 37) { //left arrow
        let element: HTMLElement = document.getElementsByClassName('owl-prev')[0] as HTMLElement;
        element.click();
      } else if (event.keyCode == 39) { //right arrow
        let element: HTMLElement = document.getElementsByClassName('owl-next')[0] as HTMLElement;
        element.click();
      }
    }
  }

  goToOpenSea(token: any) {
    window.open(token.openSeaUrl, "_blank");
  }

  seeGallery() {
    this.router.navigateByUrl('/catalog');
  }

  seeDropSchedule() {
    this.router.navigateByUrl('/');
  }

  getData(data: SlidesOutputData) {}
}
