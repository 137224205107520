import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit {
  public sound: boolean = false;
  public isMobile: boolean = false;
  public transition: string = '';
  showing: string = '';
  transitionSplash: boolean = false;
  public splashscreen: boolean = true;
  public teddyInfo: boolean = false;
  last_path: string = '';

  constructor(private router: Router, private _activatedRoute: ActivatedRoute, private navigation: NavigationService) {}

  ngOnInit() {
    const last_path_index = this.navigation.history.length - 2;
    if (last_path_index >= 0) {
      this.last_path = this.navigation.history[last_path_index];
      const ref_path = this.navigation.history[last_path_index - 1];
      this.showContent();
      if (this.last_path === '/catalog/nextgen' && ref_path === '/') {
        this.showDetails('avatars');
      } else {
        this.showing === '';
      }
    }

    const ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.isMobile = true;
    }
  }

  toggleSound() {
    this.sound = !this.sound;
  }

  showDetails(item: string) {
    this.showing = item;
    this.transition = 'in';
  }

  closeDetails() {
    this.transition = 'out';
    setTimeout(() => {
      this.showing = '';
    }, 200);
  }

  showContent() {
    this.transitionSplash = true;
    setTimeout(() => {
      this.splashscreen = false;
      this.sound = true;
    }, 500);
  }

  async goToDropSchedule() {
    await this.router.navigateByUrl('/history');
  }

  async goToCatalog() {
    await this.router.navigateByUrl('/catalog');
  }

  async goToNextGenPreview() {
    await this.router.navigateByUrl('/catalog/nextgen');
  }

  async goToMintedverse() {
    await this.router.navigateByUrl('/mintedverse');
  }

  async goToTeddyExplorers() {
    await this.router.navigateByUrl('/teddyexplorers');
  }

  showTeddyInfo() {
    this.teddyInfo = true;
  }

  closeToyInfo() {
    this.teddyInfo = false;
  }
}
