<div class="backdrop" [ngClass]="{ 'super-zoom': viewing === 'quadrant-4' }"></div>
<div class="planets" [ngClass]="{ 'super-zoom': viewing === 'quadrant-4' }">
  <h1 class="visually-hidden">Mintedverse Qudrant 4</h1>
  <div
    class="planet blue-planet blue-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Saturata"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet pink-planet pink-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Momastaroa"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet yellow-planet yellow-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Degenomema"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Awepowea"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet blue-planet blue-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Abundata"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet pink-planet pink-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Tedika"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet yellow-planet yellow-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Musipoa"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Zoroajola"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet blue-planet blue-planet-3"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Googaroa"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet pink-planet pink-planet-3"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Modragoa"
    aria-label="Theme Planet"
  ></div>
</div>
<div class="overlay"></div>
