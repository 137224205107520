import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-avatar-view',
  templateUrl: './avatar-view.component.html',
  styleUrls: ['./avatar-view.component.sass']
})
export class AvatarViewComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  async goToLaunchpad() {
    await this.router.navigateByUrl('/portal/launchpad');
  }

}
