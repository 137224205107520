<div *ngIf="showing !== ''" id="quadrantFourPanels" [ngClass]="{'transition-in': transition === 'in', 'transition-out': transition === 'out', 'gen-planet': showing === 'gen-planet'}" class="container-fluid">
  <div class="row">
    <div class="col-6 large-image-wrapper">
      <div class="large-image"></div>
    </div>
    <div class="col-12 col-md-6 p-5 reversed panel-content">

      <!-- Generic Planet -->
      <div *ngIf="showing === 'gen-planet'">
        <app-generic-planet-content></app-generic-planet-content>
      </div>

    </div>
  </div>
  <!-- Close -->
  <div class="close-button" (click)="closeDetails.emit()" (tap)="closeDetails.emit()"></div>
</div>
