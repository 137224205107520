<header-component></header-component>

<div id="dropScheduleComponent" class="container-fluid">
  <h1 class="visually-hidden">History</h1>
  <div class="row">
    <div class="col-12 teddy-video-container">
      <iframe
        src="https://player.vimeo.com/video/654449651?h=260f8eadba&autoplay=1&loop=1&autopause=0&muted=1&controls=0"
        width="100%"
        height="550px"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        muted
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex flex-column align-items-center divider-bottom text-center px-4 mb-5">
      <!--
      <h1 class="mt-5 color-gradient-pale">Getting In Early Has Its Perks</h1>
      <h3 class="mb-3 mb-sm-5 reversed">Mint a teddy from drop 1 and get bonus perks from drop 2 & beyond</h3>
      -->
    </div>
  </div>
  <!-- Intro -->
  <div class="row divider-bottom">
    <div class="col-12">
      <div class="container">
        <div class="mb-4 mb-sm-5 row">
          <div class="col-lg-2"></div>
          <div class="col-12 col-lg-8">
            <h1 class="text-center color-gradient-pale">
              Minted Teddy started with a simple question: What if a teddy becomes a kid's greatest asset?
            </h1>
            <p class="text-center reversed">
              We had ideas how to make it happen but didn’t know exactly what combination will work. So we launched a
              cascading drop that allows us to iterate and explore while rewarding early supporters.
            </p>
            <p class="text-center reversed">
              The mission is to create the most impactful teddy for future generations - a global toy brand shaped by
              the exclusive 11,111 collector club where kids and parents create and collaborate.
            </p>
            <!-- Video -->
            <div class="d-flex align-items-center justify-content-center my-4">
              <button (click)="showVideo('promo-2')" class="mx-2">Explorers Teddy Vision</button>
              <button (click)="showVideo('promo-3')" class="secondary mx-2">The Mintedverse Explained by a Kid</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Drop Schedule -->
  <div class="row pt-5 divider-bottom">
    <div class="col-12">
      <div class="container">
        <div class="mb-4 mb-sm-5 row">
          <div class="col-lg-2"></div>
          <div class="col-12 col-lg-8 drop-schedule">
            <div class="row pt-0 mb-2">
              <div class="col-12">
                <h1 class="color-gradient-pale">Drop Schedule</h1>
              </div>
            </div>
            <div class="row item">
              <div class="col-4">
                <span class="h2">OG (VIP)</span>
              </div>
              <div class="col-8">
                <div class="reversed">
                  <p class="text-lg mb-1 bold">100 Custom Teddies (0.33&nbsp;eth)</p>
                  <p>Get 1 early access teddy NFT plus all the things from Collection&nbsp;#1</p>
                  <p><span class="highlight">Sold out</span></p>
                </div>
              </div>
            </div>
            <div class="row item">
              <div class="col-4">
                <span class="h2">Series 1</span>
              </div>
              <div class="col-8">
                <div class="pb-3 reversed">
                  <p class="text-lg mb-1 bold">1000 Teddies (0.11&nbsp;eth)</p>
                  <p>Get 1 teddy NFT from this drop plus all the things from Collection&nbsp;#2</p>
                  <!-- Join the priority list -->
                  <!-- Wallet connected -->
                  <p><span class="highlight">Sold out</span></p>
                  <!--
                  <div *ngIf="web3Service.accounts && web3Service.accounts.length > 0 " class="inline-block mr-3">
                    <div *ngIf="web3Service.isMetaMask">
                      <button [disabled]="minting || loadingVoucher" (click)="mintVoucher()" class="my-3"><span *ngIf="minting">Minting...</span><span *ngIf="!minting">Mint a Teddy Now</span></button>
                      <p><i>** Yes, gas is expensive... but a stuck teddy is worse.  We recommend accepting the suggested gas -- or you risk a failed mint (which is not fun).</i></p>
                    </div>
                    <div *ngIf="!web3Service.isMetaMask">
                      <p><i>Currently, we only support MetaMask for this public mint.</i></p>
                    </div>
                  </div>

                  <a
                    *ngIf="web3Service.supportsWeb3 && !(web3Service.accounts && web3Service.accounts.length > 0)"
                    (click)="connectWallet()">
                    Connect Wallet to Mint a Teddy Now
                  </a>
                  <p *ngIf="!web3Service.supportsWeb3" class="not-supported">
                    Install the Metamask browser extension and connect your wallet to mint a teddy now.
                  </p>
                  -->
                </div>
              </div>
            </div>
            <div class="row item">
              <div class="col-4">
                <span class="h2">Explorers</span>
              </div>
              <div class="col-8 reversed">
                <div>
                  <p class="text-lg mb-1 bold">2,500 Teddies</p>
                  <p class="mb-4">
                    A culmination of a one-year design exercise by Claudio Bellini and guest artists. Includes a 3D
                    teddy avatar, and a sustainable physical toy.
                  </p>
                  <p class="text-yellow">February 26, 2023</p>
                </div>
              </div>
            </div>
            <div class="row item">
              <div class="col-4">
                <span class="h2">Gen3</span>
              </div>
              <div class="col-8 reversed">
                <div>
                  <p class="text-lg mb-1 bold">7,500 Teddies</p>
                  <p class="mb-4">
                    Will be co-created with our community on Mintedverse planets to make the most epic NFT collection of
                    all time.
                  </p>
                </div>
              </div>
            </div>
            <div class="row item">
              <div class="col-4">
                <span class="h2">Semifinal</span>
              </div>
              <div class="col-8 reversed">
                <div>
                  <p class="text-lg mb-1 bold">10 Minted&nbsp;Teddies Auction</p>
                  <p class="mb-4">Exclusively for Minted Teddy NFT holders</p>
                </div>
              </div>
            </div>
            <div class="row item">
              <div class="col-4">
                <span class="h2">Final</span>
              </div>
              <div class="col-8 reversed">
                <div>
                  <p class="text-lg mb-1 bold">1 Minted&nbsp;Teddy Auction</p>
                  <p class="mb-4">Exclusively for Minted&nbsp;Teddy NFT holders</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="mb-5 row">
          <div class="col-12">
            <img src="assets/img/teddies-x4-horizontal.jpg" class="teddies-graphic" />
          </div>
        </div> -->
      </div>
    </div>
  </div>

  <!-- Text -->
  <div id="team-info-block" class="py-5 divider-bottom">
    <div class="col-12">
      <div class="container">
        <div class="row">
          <div class="col-lg-2"></div>
          <div class="col-12 col-lg-8">
            <h1 class="reversed text-center m-0">
              Minted Teddy is a brainchild from the Toymint team. We are shaping the future of sustainable / valuable
              kids toys. Getting the next generation off to a financial headstart without compromising the joys of being
              naughty little rascals. With toys.
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Team -->
  <div id="team-block" class="py-5">
    <div class="team-member">
      <a href="https://www.linkedin.com/in/zkovacevic/" target="_blank">
        <div class="photo-container">
          <div class="card">
            <div class="photo-wrapper">
              <img src="/assets/img/team/team-zoran.jpeg" />
            </div>
          </div>
        </div>
        <div class="text">
          <h1>Zoran Kovacevic</h1>
          <p>Founder & CEO</p>
        </div>
      </a>
    </div>

    <div class="team-member">
      <a href="https://www.linkedin.com/in/garettrogers/" target="_blank">
        <div class="photo-container">
          <div class="card">
            <div class="photo-wrapper">
              <img src="/assets/img/team/team-garett.jpeg" />
            </div>
          </div>
        </div>
        <div class="text">
          <h1>Garett Rogers</h1>
          <p>Co-Founder & CTO</p>
        </div>
      </a>
    </div>

    <div class="team-member">
      <a href="https://www.linkedin.com/in/hokrystal/" target="_blank">
        <div class="photo-container">
          <div class="card">
            <div class="photo-wrapper">
              <img src="/assets/img/team/team-krystal.jpeg" />
            </div>
          </div>
        </div>
        <div class="text">
          <h1>Krystal Ho</h1>
          <p>Co-Founder & CXO</p>
        </div>
      </a>
    </div>

    <div class="team-member">
      <a href="https://instagram.com/cloud.i0?utm_medium=copy_link" target="_blank">
        <div class="photo-container">
          <div class="card">
            <div class="photo-wrapper">
              <img src="/assets/img/team/team-claudio.jpeg" />
            </div>
          </div>
        </div>
        <div class="text">
          <h1>Claudio Bellini</h1>
          <p>3D Artist</p>
        </div>
      </a>
    </div>

    <div class="team-member">
      <a href="https://www.linkedin.com/in/isisshiffer/" target="_blank">
        <div class="photo-container">
          <div class="card">
            <div class="photo-wrapper">
              <img src="/assets/img/team/team-isis.jpeg" />
            </div>
          </div>
        </div>
        <div class="text">
          <h1>Isis Shiffer</h1>
          <p>Toy Designer</p>
        </div>
      </a>
    </div>

    <div class="team-member">
      <a href="https://twitter.com/NFTKIDx" target="_blank">
        <div class="photo-container">
          <div class="card">
            <div class="photo-wrapper">
              <img src="/assets/img/team/team-nftkid.png" />
            </div>
          </div>
        </div>
        <div class="text">
          <h1>NFTKid</h1>
          <p>Kid Ambassador</p>
        </div>
      </a>
    </div>

    <div class="team-member">
      <a href="https://www.linkedin.com/in/victoriajwong/" target="_blank">
        <div class="photo-container">
          <div class="card">
            <div class="photo-wrapper">
              <img src="/assets/img/team/team-victoria.jpeg" />
            </div>
          </div>
        </div>
        <div class="text">
          <h1>Victoria Wong</h1>
          <p>UI/UX Designer</p>
        </div>
      </a>
    </div>

    <div class="team-member">
      <a href="https://www.linkedin.com/in/herbert-chow-21635b1a/" target="_blank">
        <div class="photo-container">
          <div class="card">
            <div class="photo-wrapper">
              <img src="/assets/img/team/team-herb.jpeg" />
            </div>
          </div>
        </div>
        <div class="text">
          <h1>Herbert Chow</h1>
          <p>AR Developer</p>
        </div>
      </a>
    </div>
  </div>
</div>

<app-show-video *ngIf="video !== ''" [video]="video" (hideVideo)="hideVideo()"> </app-show-video>

<footer-component></footer-component>
