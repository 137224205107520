<header-component></header-component>

<div id="invitationComponent" class="container-fluid">
    <div class="row" *ngIf="!processing">
      <div class="col-12 teddy-video-container">
        <iframe src="https://player.vimeo.com/video/654449651?h=260f8eadba&autoplay=1&loop=1&autopause=0&muted=1&controls=0" width="100%" height="550px" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" muted allowfullscreen></iframe>
        <div class="text-content">
          <div *ngIf="processing" class="loading">
            <div class="spinner-border text-light" role="status"></div>
            <h2>Loading...</h2>
          </div>
          <h2 *ngIf="!processing">Join the Priority List</h2>
        </div>
      </div>
    </div>
  <div *ngIf="!processing" class="container pt-4 pt-sm-5 pb-5">
    <div class="row">
      <div class="col-12">
        <div class="content-container">
          <!-- Not Logged In -->
          <div *ngIf="!(web3Service.accounts && web3Service.accounts.length > 0) && invitation" class="content">
            <p>To join the priority list, you must first connect your wallet.</p>
            <p><button [disabled]="reserving" (click)="connectWallet()">Connect Wallet</button></p>
          </div>
          <!-- Logged In -->
          <div *ngIf="web3Service.accounts && web3Service.accounts.length > 0 && invitation" class="content">
            <p class="mb-3">
              Get first dibs to mint a teddy for the next drop.
            </p>
            <div class="reserve-form">
              <p *ngIf="errorMessage" class="error-message my-4">
                {{ errorMessage }}
              </p>
              <div class="form-group">
                <label>Your Name:</label>
                <input type="text" [(ngModel)]="reserveName" placeholder="Jane Doe" />
              </div>
              <div class="form-group">
                <label>Your Email:</label>
                <input type="text" [(ngModel)]="reserveEmail" placeholder="jane@doe.com" />
              </div>
              <button [disabled]="reserving" (click)="reserveTeddy()" class="mt-3">
                <span *ngIf="reserving">Reserving...</span><span *ngIf="!reserving">Reserve a Teddy Now</span>
              </button>
            </div>
          </div>
          <!-- Invitation Used -->
          <div *ngIf="!invitation" class="content text-center pb-5">
            <span class="notification">Sorry, this invitation has already been used.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer-component></footer-component>
