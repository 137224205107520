import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promo-videos',
  templateUrl: './promo-videos.component.html',
  styleUrls: ['./promo-videos.component.sass']
})
export class PromoVideosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
