<div id="quadrantComponent" class="root">
  <div class="point">
    <div class="canvas">
      <!-- Planets view -->
      <app-quadrant-one
        id="quadrant-1"
        class="quadrant"
        [quadrant]="quadrant"
        [viewing]="viewing"
        (selectPlanet)="selectPlanet($event)"
        *ngIf="pathname.includes('quadrant-3')">
      </app-quadrant-one>
      <app-quadrant-two
        id="quadrant-2"
        class="quadrant"
        [quadrant]="quadrant"
        [viewing]="viewing"
        (selectPlanet)="selectPlanet($event)"
        *ngIf="pathname.includes('quadrant-4')">
      </app-quadrant-two>
      <app-quadrant-three
        id="quadrant-3"
        class="quadrant"
        [quadrant]="quadrant"
        [viewing]="viewing"
        (selectPlanet)="selectPlanet($event)"
        *ngIf="pathname.includes('quadrant-5')">
      </app-quadrant-three>
      <app-quadrant-four
        id="quadrant-4"
        class="quadrant"
        [quadrant]="quadrant"
        [viewing]="viewing"
        (selectPlanet)="selectPlanet($event)"
        *ngIf="pathname.includes('quadrant-2')">
      </app-quadrant-four>
      <app-quadrant-five
        id="quadrant-5"
        class="quadrant"
        [quadrant]="quadrant"
        [viewing]="viewing"
        (selectPlanet)="selectPlanet($event)"
        *ngIf="pathname.includes('quadrant-1')">
      </app-quadrant-five>
      <app-quadrant-six
        id="quadrant-6"
        class="quadrant"
        [quadrant]="quadrant"
        [viewing]="viewing"
        (selectPlanet)="selectPlanet($event)"
        *ngIf="pathname.includes('quadrant-6')">
      </app-quadrant-six>
      <!-- End planets view -->
    </div>
  </div>
</div>

<!-- Panels view -->
<app-quadrant-one-panels
  id="detailsPanel"
  [showing]="showing"
  [transition]="transition"
  (closeDetails)="closeDetails()"
  (showVideo)="showVideo($event)"
  *ngIf="pathname.includes('quadrant-3')">
</app-quadrant-one-panels>
<app-quadrant-two-panels
  id="detailsPanel"
  [showing]="showing"
  [transition]="transition"
  (closeDetails)="closeDetails()"
  (showVideo)="showVideo($event)"
  *ngIf="pathname.includes('quadrant-4')">
</app-quadrant-two-panels>
<app-quadrant-three-panels
  id="detailsPanel"
  [showing]="showing"
  [transition]="transition"
  (closeDetails)="closeDetails()"
  (showVideo)="showVideo($event)"
  *ngIf="pathname.includes('quadrant-5')">
</app-quadrant-three-panels>
<app-quadrant-four-panels
  id="detailsPanel"
  [showing]="showing"
  [transition]="transition"
  (closeDetails)="closeDetails()"
  (showVideo)="showVideo($event)"
  *ngIf="pathname.includes('quadrant-2')">
</app-quadrant-four-panels>
<app-quadrant-five-panels
  id="detailsPanel"
  [showing]="showing"
  [transition]="transition"
  (closeDetails)="closeDetails()"
  (showVideo)="showVideo($event)"
  (showPopup)="showPopup($event)"
  *ngIf="pathname.includes('quadrant-1')">
</app-quadrant-five-panels>
<app-quadrant-six-panels
  id="detailsPanel"
  [showing]="showing"
  [transition]="transition"
  (closeDetails)="closeDetails()"
  (showVideo)="showVideo($event)"
  *ngIf="pathname.includes('quadrant-6')">
</app-quadrant-six-panels>
<!-- End panels view -->

<app-show-video
  *ngIf="video !== ''"
  [video]="video"
  (hideVideo)="hideVideo()">
</app-show-video>

<app-popup-dialog-box
  *ngIf="popup !== ''"
  [popupContent]="popup"
  (hidePopup)="hidePopup()">
</app-popup-dialog-box>

<div *ngIf="showing !== ''" class="overlay" (click)="closeDetails()" (tap)="closeDetails()"></div>

<div class="back-button" *ngIf="showing === ''">
  <button (click)="goToMintedverse()" (tap)="goToMintedverse()"><i class="fas fa-chevron-left p-2"></i> Back to Mintedverse</button>
</div>
