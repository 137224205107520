import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-launchpad',
  templateUrl: './launchpad.component.html',
  styleUrls: ['./launchpad.component.sass']
})
export class LaunchpadComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  async goToChooseTeddy() {
    await this.router.navigateByUrl('/portal/choose-teddy-2');
  }

}
