import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  title = 'mt-web';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta
  ) {}

  ngOnInit() {
    const title = 'Minted Teddy • A New Digital Frontier Ready for Exploration';
    const desc =
      'Minted Teddy is a metaverse toy on a mission to become the most impactful teddy for future generations. Give your kiddo a fun toy that blurs the lines between the physical and digital worlds.';

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      var rt = this.getChild(this.activatedRoute);

      rt.data.subscribe((data: any) => {
        console.log(data);
        if (data.title) {
          this.titleService.setTitle(data.title);
        } else {
          this.titleService.setTitle(title);
        }

        if (data.description) {
          this.metaService.updateTag({ name: 'description', content: data.descrption });
        } else {
          this.metaService.updateTag({ name: 'description', content: desc });
        }

        if (data.robots) {
          this.metaService.updateTag({ name: 'robots', content: data.robots });
        } else {
          this.metaService.updateTag({ name: 'robots', content: 'follow,index' });
        }

        if (data.ogUrl) {
          this.metaService.updateTag({ property: 'og:url', content: data.ogUrl });
        } else {
          this.metaService.updateTag({ property: 'og:url', content: this.router.url });
        }

        if (data.ogTitle) {
          this.metaService.updateTag({ property: 'og:title', content: data.ogTitle });
        } else {
          this.metaService.updateTag({
            property: 'og:title',
            content: title,
          });
        }

        if (data.ogDescription) {
          this.metaService.updateTag({ property: 'og:description', content: data.ogDescription });
        } else {
          if (data.description) {
            this.metaService.updateTag({ property: 'og:description', content: data.description });
          } else {
            this.metaService.updateTag({ property: 'og:description', content: desc });
          }
        }

        if (data.ogImage) {
          this.metaService.updateTag({ property: 'og:image', content: data.ogImage });
        } else {
          this.metaService.updateTag({ property: 'og:image', content: '/assets/img/cosmic-teddy-spectre.jpg' });
        }
      });
    });
  }

  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
