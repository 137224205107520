import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quadrant-two-panels',
  templateUrl: './quadrant-two-panels.component.html',
  styleUrls: ['./quadrant-two-panels.component.sass']
})
export class QuadrantTwoPanelsComponent implements OnInit {
  @Input() showing = '';
  @Input() transition = '';
  @Output() closeDetails = new EventEmitter<string>();
  @Output() showVideo = new EventEmitter<string>();
  video: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
