<div id="inlineVideoComponent">
  <div class="video-wrapper inline-video" *ngIf="videoPlayback">
    <!-- Birthday Planet -->
    <iframe
      *ngIf="showing === 'birthday-planet'"
      src="https://player.vimeo.com/video/780242688?autoplay=1&loop=1&autopause=0&muted=1&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>

    <!-- Music Planet -->
    <iframe
      *ngIf="showing === 'music-planet'"
      src="https://player.vimeo.com/video/780242841?autoplay=1&loop=1&autopause=0&muted=1&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>

    <!-- Toys Planet -->
    <iframe
      *ngIf="showing === 'toys-planet'"
      src="https://player.vimeo.com/video/780243150?autoplay=1&loop=1&autopause=0&muted=1&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>

    <!-- Fashion Planet -->
    <iframe
      *ngIf="showing === 'fashion-planet'"
      src="https://player.vimeo.com/video/780242779?autoplay=1&loop=1&autopause=0&muted=1&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>

    <!-- Teddy Explorer -->
    <iframe
      *ngIf="showing === 'teddy-explorer'"
      src="https://player.vimeo.com/video/739905185?autoplay=1&loop=1&autopause=0&muted=1&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>

    <!-- Teddy Portal -->
    <iframe
      *ngIf="showing === 'teddy-portal'"
      src="https://player.vimeo.com/video/798291983?autoplay=1&loop=1&autopause=0&muted=1&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>

    <!-- Music Planet Teaser -->
    <iframe
      *ngIf="showing === 'music-planet-teaser'"
      src="https://player.vimeo.com/video/798294421?autoplay=1&loop=1&autopause=0&muted=1&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <!-- <div class="control-icon">
    <i
      class="fas fa-pause-circle"
      *ngIf="videoPlayback"
      (click)="toggleVideoPlayback()"
      (tap)="toggleVideoPlayback()"></i>
    <i
      class="fas fa-play-circle"
      *ngIf="!videoPlayback"
      (click)="toggleVideoPlayback()"
      (tap)="toggleVideoPlayback()"></i>
  </div> -->

  <script src="https://player.vimeo.com/api/player.js"></script>
</div>
