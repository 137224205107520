<div id="headerComponent" [ngClass]="web3Service.accounts && web3Service.accounts.length > 0 ? 'container-fluid logged-in': 'container-fluid'">
  <!-- Header background -->
  <div class="row background">
    <div class="col-12 content-container">
      <div class="ellipse-locations">
        <img src="/assets/img/ellipse1.png" class="ellipse1" />
        <img src="/assets/img/ellipse2.png" class="ellipse2" />
      </div>
    </div>
  </div>
  <!-- Header content -->
  <div class="row header-content d-flex">
    <div class="col-12 d-flex flex-wrap w-100 justify-content-between align-items-center px-4 px-sm-5">
      <!-- Logo -->
      <div class="logo-wrapper d-flex w-100 justify-content-center">
        <div class="logo" (click)="goToMintedverse()"></div>
      </div>
      <!-- Hamburger icon -->
      <div class='mobile-toggle d-flex
      align-items-center' [ngClass]="{ 'menu-open': showMobileNav === true }">
        <i class="fas fa-hamburger reversed" (click)="toggleMobileMenu()"></i>
      </div>
      <!-- Nav -->
      <div id="Nav" class="d-flex w-100 flex-wrap align-items-center justify-content-between" [ngClass]="{ 'mobile-show': showMobileNav === true, 'mobile-hidden': showMobileNav === false }">
        <!-- Left content -->
        <div class="d-flex align-items-center left-content">
          <div *ngIf="!reservePage" class="links-group">
            <button (click)="goHome()"
              class="header-link"
              [ngClass]="{current: url === '/'}">
              Home
            </button>
            <button (click)="gotoDropSchedule()"
              class="header-link"
              [ngClass]="{current: url === '/history'}">
              History
            </button>
            <button (click)="gotoCatalog()"
              class="header-link"
              [ngClass]="{current: url.includes('/catalog')}">
              Catalog
            </button>
            <button
              (click)="goToMintedverse()"
              class="header-link"
              [ngClass]="{current: url === '/mintedverse' || url === '/noaccess'}">
              Mintedverse
            </button>
          </div>
        </div>

        <!-- Connect Wallet Button -->
        <div *ngIf="web3Service.supportsWeb3 && !(web3Service.accounts && web3Service.accounts.length > 0)" class="d-flex justify-content-end align-items-center my-3 wallet-not-connected">
          <button (click)="connectWallet()">Connect Wallet</button>
        </div>

        <!-- Wallet connected -->
        <div *ngIf="web3Service.supportsWeb3 && (web3Service.accounts && web3Service.accounts.length > 0)" class="d-flex align-items-center my-3 wallet-connected">
          <button (click)="gotoMyTeddies()" class="header-link"
            [ngClass]="{current: url.includes('/my-teddies')}">
            My Teddies
          </button>
          <div class="connected-indicator reversed text-sm">
            Wallet Connected ✅ {{ smartTrim(web3Service.selectedAccount, 8) }}
          </div>
        </div>

        <!-- Not supported -->
        <div *ngIf="!web3Service.supportsWeb3" class="not-supported reversed">
          Your browser doesn't support Web3. Consider installing the Metamask browser extension, or use the Metamask wallet on your phone to purchase a teddy.
        </div>
      </div>
    </div>
  </div>

</div>
