import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IReservationInvitation } from '../interfaces/IReservationInvitation';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  async notifyBirthdayPlanet(
    emailAddress: string
  ): Promise<{ success: boolean; newInvitation: IReservationInvitation }> {
    return this.http
      .post<{ success: boolean; newInvitation: IReservationInvitation }>(
        `${environment.apiUrl}/notifications/birthdayplanet`,
        { emailAddress },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .toPromise();
  }

  async notifyFashionPlanet(
    emailAddress: string
  ): Promise<{ success: boolean; newInvitation: IReservationInvitation }> {
    return this.http
      .post<{ success: boolean; newInvitation: IReservationInvitation }>(
        `${environment.apiUrl}/notifications/fashionplanet`,
        { emailAddress },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .toPromise();
  }

  async notifyMusicPlanet(emailAddress: string): Promise<{ success: boolean; newInvitation: IReservationInvitation }> {
    return this.http
      .post<{ success: boolean; newInvitation: IReservationInvitation }>(
        `${environment.apiUrl}/notifications/musicplanet`,
        { emailAddress },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .toPromise();
  }

  async notifyToysPlanet(emailAddress: string): Promise<{ success: boolean; newInvitation: IReservationInvitation }> {
    return this.http
      .post<{ success: boolean; newInvitation: IReservationInvitation }>(
        `${environment.apiUrl}/notifications/toysplanet`,
        { emailAddress },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .toPromise();
  }
}
