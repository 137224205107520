import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quadrant-two',
  templateUrl: './quadrant-two.component.html',
  styleUrls: ['./quadrant-two.component.sass']
})
export class QuadrantTwoComponent implements OnInit {
  @Input() quadrant: string = '';
  @Input() viewing: string = '';
  @Output() selectPlanet = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}
