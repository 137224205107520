import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-teddy-explorers',
  templateUrl: './teddy-explorers.component.html',
  styleUrls: ['./teddy-explorers.component.sass'],
})
export class TeddyExplorersComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  async goToMint() {
    await this.router.navigateByUrl('/mint');
  }

  async goToMintedverse() {
    await this.router.navigateByUrl('/mintedverse');
  }
}
