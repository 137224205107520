import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { estimateFees } from '@mycrypto/gas-estimation';
import { IReservationInvitation } from '../../interfaces/IReservationInvitation';
import { ContractsService } from '../../services/contracts.service';
import { Web3Service } from '../../services/web3.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.sass']
})
export class InvitationComponent implements OnInit {
  reserving: boolean = false;
  processing: boolean = true;
  reserveName: string = '';
  reserveEmail: string = '';
  invitationId: string = '';
  errorMessage: string = '';
  invitation!: IReservationInvitation;
  constructor(private router: Router, public web3Service: Web3Service, private _activatedRoute: ActivatedRoute, private contractService: ContractsService) { }

  ngOnInit(): void {
    this.processing = true;
    this._activatedRoute.params.subscribe(async parameter => {
      this.invitationId = parameter.invitationId;
      await this.web3Service.getAccounts();
      try {
        this.invitation = await this.contractService.getReservationInvitation(environment.contractId, this.invitationId);
      } catch (ex) {

      } finally {
        this.processing = false;
      }

    })
  }

  async reserveTeddy() {
    this.reserving = true;
    this.errorMessage = '';

    await this.web3Service.getAccounts();

    if (!(this.reserveEmail.trim() && this.reserveName.trim() && this.web3Service.selectedAccount)) {
      this.errorMessage = 'Please enter all the fields.';
      this.reserving = false;
      return;
    }

    const contract = await this.contractService.getContract(environment.contractId);

    try {
      await this.contractService.reserveByInvitation(environment.contractId, this.invitationId, this.web3Service.selectedAccount, this.reserveName, this.reserveEmail);
      this.errorMessage = 'Thank you! You\'re now on the priority list for the next drop.'
    } catch (ex) {
      this.errorMessage = ex.message;
    } finally {
      this.reserving = false;
    }

  }

  async connectWallet() {
    await this.web3Service.connectWallet();
  }

}
