<div class="backdrop" [ngClass]="{ 'super-zoom': viewing === 'quadrant-5' }"></div>
<div class="planets" [ngClass]="{ 'super-zoom': viewing === 'quadrant-5' }">
  <h1 class="visually-hidden">Mintedverse Qudrant 5</h1>
  <div
    class="planet og-planet"
    (click)="selectPlanet.emit('og-planet')"
    (tap)="selectPlanet.emit('og-planet')"
    matTooltip="OG Planet"
    aria-label="O G Planet"
  ></div>
  <div
    class="planet next-gen-planet"
    (click)="selectPlanet.emit('next-gen-planet')"
    (tap)="selectPlanet.emit('next-gen-planet')"
    matTooltip="Explorers Planet"
    aria-label="Explorers Planet"
  ></div>
  <div
    class="planet series-1-planet"
    (click)="selectPlanet.emit('series-1-planet')"
    (tap)="selectPlanet.emit('series-1-planet')"
    matTooltip="Series 1 Planet"
    aria-label="Series 1 Planet"
  ></div>
  <div
    class="planet william-willoughby-iii-moon"
    (click)="selectPlanet.emit('william-willoughby-iii-moon')"
    (tap)="selectPlanet.emit('william-willoughby-iii-moon')"
    matTooltip="William Willoughby III Moon"
    aria-label="William Willoughby III Moon"
  ></div>
  <div
    class="planet brenda-chen-moon"
    (click)="selectPlanet.emit('brenda-chen-moon')"
    (tap)="selectPlanet.emit('brenda-chen-moon')"
    matTooltip="Brenda Chen Moon"
    aria-label="Brenda Chen Moon"
  ></div>
  <div
    class="planet theodoru-moon"
    (click)="selectPlanet.emit('theodoru-moon')"
    (tap)="selectPlanet.emit('theodoru-moon')"
    matTooltip="Theodoru Moon"
    aria-label="Theodoru Moon"
  ></div>
  <div
    class="planet mooncats-moon"
    (click)="selectPlanet.emit('mooncats-moon')"
    (tap)="selectPlanet.emit('mooncats-moon')"
    matTooltip="MoonCats Moon"
    aria-label="MoonCats Moon"
  ></div>
  <div
    class="planet music-planet"
    (click)="selectPlanet.emit('music-planet')"
    (tap)="selectPlanet.emit('music-planet')"
    matTooltip="Music Planet"
    aria-label="Music Planet"
  ></div>
  <div
    class="planet birthday-planet"
    (click)="selectPlanet.emit('birthday-planet')"
    (tap)="selectPlanet.emit('birthday-planet')"
    matTooltip="Birthday Planet"
    aria-label="Birthday Planet"
  ></div>
  <div
    class="planet toys-planet"
    (click)="selectPlanet.emit('toys-planet')"
    (tap)="selectPlanet.emit('toys-planet')"
    matTooltip="Toys Planet"
    aria-label="Toys Planet"
  ></div>
  <div
    class="planet fashion-planet"
    (click)="selectPlanet.emit('fashion-planet')"
    (tap)="selectPlanet.emit('fashion-planet')"
    matTooltip="Fashion Planet"
    aria-label="Fashion Planet"
  ></div>
</div>
<div class="overlay"></div>
