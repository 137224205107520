<footer id="footerComponent">
  <div class="container">
    <div class="row">
      <div class="col-12 p-0 d-flex justify-content-between">
        <span class="py-5 px-4 px-sm-5 footer-text">
          Need Help? DM us on <a href="https://twitter.com/mintedteddy" target="_blank" class="mx-2">Twitter</a>
        </span>
        <a href="/faq" class="py-5 px-4 px-sm-5 footer-text static">FAQ</a>
      </div>
    </div>
  </div>
</footer>
