import { Component, OnInit } from '@angular/core';
import { ContractsService } from 'src/app/services/contracts.service';
import { IpfsService } from 'src/app/services/ipfs.service';
import { Web3Service } from 'src/app/services/web3.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.sass']
})
export class AccountComponent implements OnInit {
  tokens: {token: string, url: string, opensea: string, metaData: any}[] = [];
  loading: boolean = false;

  constructor(
    private ipfsService: IpfsService,
    private web3Service: Web3Service,
    private router: Router,
    private contractService: ContractsService) { }

  async ngOnInit(): Promise<void> {
    // Contracts
    this.loading = true;
    const contract = await this.contractService.getContract(environment.contractId);
    const selectedContract = new this.web3Service.web3.eth.Contract(JSON.parse(contract.Contract).abi as any, contract.Address);

    // Tokens
    const tokens = await selectedContract.methods.getTokenIds(this.web3Service.accounts[0]).call();
    for (const token of tokens) {
      const url = await selectedContract.methods.tokenURI(token).call()
      try {
        const metaData = await this.ipfsService.getMetadata(url);
        metaData.image_link = metaData.image.replace('ipfs://', 'https://ipfs.mintedteddy.com/cdn-cgi/image/fit=scale-down,width=328/ipfs/')
        metaData.token_link = environment.siteUrl + '/token/' + token;
        this.tokens.push({ token, url, opensea: `https://testnets.opensea.io/assets/${contract.Address}/${token}`, metaData });
      } catch (ex) {
        console.log('invalid metadata');
      }
    }

    this.loading = false;
  }

  async goHome() {
    await this.router.navigateByUrl('/');
  }

}
