<div id="showVideoComponent">
  <div class="overlay"></div>
  <!-- Video -->
  <div class="video-wrapper" (click)="hideVideo.emit()" (tap)="hideVideo.emit()">
    <!-- Explorers -->
    <iframe
      *ngIf="video === 'next-gen'"
      src="https://player.vimeo.com/video/736790984?autoplay=1&loop=1&autopause=0&muted=0&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>

    <!-- Series 1 -->
    <iframe
      *ngIf="video === 'series-1'"
      src="https://player.vimeo.com/video/660559203?autoplay=1&loop=1&autopause=0&muted=0&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>

    <!-- MT Video -->
    <iframe
      *ngIf="video === 'mt-video'"
      src="https://player.vimeo.com/video/756266734?autoplay=1&loop=1&autopause=0&muted=0&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>

    <!-- Promo 2 -->
    <iframe
      *ngIf="video === 'promo-2'"
      src="https://player.vimeo.com/video/780204597?h=f23c98f5d3?autoplay=1&loop=1&autopause=0&muted=0&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>

    <!-- Promo 3 -->
    <iframe
      *ngIf="video === 'promo-3'"
      src="https://player.vimeo.com/video/770599057?h=e0fb2e323b?autoplay=1&loop=1&autopause=0&muted=0&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>

    <!-- Music Planet Teaser -->
    <iframe
      *ngIf="video === 'music-planet-teaser'"
      src="https://player.vimeo.com/video/798294421?h=253a21b21e?autoplay=1&loop=1&autopause=0&muted=0&background=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  <!-- Close Button -->
  <div class="close-btn" (click)="hideVideo.emit()" (tap)="hideVideo.emit()"><i class="fas fa-times"></i></div>
  <script src="https://player.vimeo.com/api/player.js"></script>
</div>
