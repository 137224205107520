<div id="popupDialogBoxComponent">
  <!-- Popup Content -->
  <div class="content fade-in p-5">
    <!-- OG Council -->
    <div *ngIf="popupContent === 'og-council'">
      <h1>OG Council</h1>
      <p>The OG Council is made up of OG teddies who actively advise and participate in the Minted Teddy project.</p>
      <p>More details coming soon!</p>
      <button class="secondary mt-3" (click)="hidePopup.emit()" (tap)="hidePopup.emit()">Close</button>
    </div>

    <!-- Treasury Star -->
    <div *ngIf="popupContent === 'treasury-star'">
      <h1>Treasury Star</h1>
      <p>
        The Treasury Star symbolizes our treasury that will power the development of the Mintedverse planets. The same
        way the Sun gives life to our planet so will the Treasury Star keep Mintedverse alive and thriving.
      </p>
      <p>
        A portion of the Explorers drop funds and portion of the secondary sales royalties are going to the treasury.
      </p>
      <p>The treasury balance will display here after the drop.</p>
      <button class="secondary mt-3" (click)="hidePopup.emit()" (tap)="hidePopup.emit()">Close</button>
    </div>
  </div>
  <!-- Overlay -->
  <div class="overlay" (click)="hidePopup.emit()" (tap)="hidePopup.emit()"></div>
</div>
