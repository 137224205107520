import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-generic-planet-content',
  templateUrl: './generic-planet-content.component.html',
  styleUrls: ['./generic-planet-content.component.sass'],
})
export class GenericPlanetContentComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  mintNow() {
    this.router.navigateByUrl('/planetguardians');
  }
}
