import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Web3Service } from 'src/app/services/web3.service';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.sass']
})
export class ConnectComponent implements OnInit {
  constructor(public web3Service: Web3Service, private router: Router) { }
  
  async ngOnInit() {
    await this.web3Service.getAccounts();
  }

  async connectWallet() {
    await this.web3Service.connectWallet();
    await this.web3Service.getAccounts();
    if (this.web3Service.accounts && this.web3Service.accounts.length > 0) {
      await this.router.navigateByUrl('/');
    }
  }

}
