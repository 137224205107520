<header-component></header-component>
<div id="PromoVideos" class="container">
  <div class="row">
    <div class="col-12 col-lg-6 pt-5 pb-0">
      <h1 class="text-center pb-2">Birthday Teddy</h1>
      <iframe
        src="https://player.vimeo.com/video/830496137?h=5339dceaad?autoplay=0&loop=1&autopause=0&muted=0&background=0"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="col-12 col-lg-6 py-5">
      <h1 class="text-center pb-2">Teddy Explorer</h1>
      <iframe
        src="https://player.vimeo.com/video/830496230?h=183e1c9c8b?autoplay=0&loop=0&autopause=0&muted=0&background=0"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <script src="https://player.vimeo.com/api/player.js"></script>
  </div>
</div>
<footer-component></footer-component>
