<div
  *ngIf="showing !== ''"
  id="quadrantFivePanels"
  [ngClass]="{
    'transition-in': transition === 'in',
    'transition-out': transition === 'out',
    'music-planet': showing === 'music-planet',
    'birthday-planet': showing === 'birthday-planet',
    'toys-planet': showing === 'toys-planet',
    'fashion-planet': showing === 'fashion-planet',
    'og-planet': showing === 'og-planet',
    'series-1-planet': showing === 'series-1-planet',
    'next-gen-planet': showing === 'next-gen-planet',
    'mooncats-moon': showing === 'mooncats-moon',
    'brenda-chen-moon': showing === 'brenda-chen-moon',
    'theodoru-moon': showing === 'theodoru-moon',
    'william-willoughby-iii-moon': showing === 'william-willoughby-iii-moon'
  }"
  class="container-fluid"
>
  <div class="row">
    <div class="col-6 large-image-wrapper">
      <div class="large-image">
        <app-inline-video [showing]="showing"></app-inline-video>
      </div>
    </div>
    <div class="col-12 col-md-6 p-5 reversed panel-content">
      <!-- Music Planet -->
      <div *ngIf="showing === 'music-planet'">
        <h1>Music Planet</h1>
        <p>Music is the most powerful medium for spreading good vibes.</p>
        <p>
          Mintedverse music is uplifting and optimistic - purposefully composed to infuse the subconscious mind with
          unbridled enthusiasm and inspire creative happy thoughts.
        </p>
        <p class="bold highlight">🎶 Opening 2023 🎶</p>
        <p>
          When it opens, all teddies will be able to visit and indulge in uplifting tunes! In the meantime, check out
          Minted Teddy songs on <a href="https://open.spotify.com/artist/6uGr74c9gfdVq7iKRnramZ">Spotify.</a>
        </p>
        <div *ngIf="musicPlanetSubmitted">
          <hr />
          Thanks! We will be in touch soon!
        </div>
        <div *ngIf="!musicPlanetSubmitted" class="notify-form-group">
          <input
            id="musicPlanetInput"
            type="text"
            [(ngModel)]="musicPlanetNotifyEmail"
            placeholder="jane@doe.com"
            (tap)="focusInput($event)"
          />
          <button
            style="padding-top: 5px; padding-bottom: 5px"
            (click)="musicPlanetNotify()"
            (tap)="musicPlanetNotify()"
          >
            Notify Me
          </button>
          <div class="d-flex flex-wrap my-3 btn-container">
            <!-- <button class="secondary my-2 mx-2" disabled>Button 2</button> -->
            <button
              class="secondary my-2 mx-2"
              (click)="showVideo.emit('music-planet-teaser')"
              (tap)="showVideo.emit('music-planet-teaser')"
            >
              Teaser Video
            </button>
          </div>
        </div>
      </div>

      <!-- Birthday Planet -->
      <div *ngIf="showing === 'birthday-planet'">
        <h1>Teddy Party</h1>
        <p>
          In the Mintedverse, birthday parties are different. Teddies are intentional in their gifting, and friends work
          together to gift impactful, long-lasting birthday presents. 🎁✨
        </p>
        <p>
          Teddies use Teddy Party to send invites and organize gift pools. Try it for your party here:
          <a href="https://teddy.party" target="_blank">teddy.party</a>
        </p>
        <div *ngIf="birthdayPlanetSubmitted">
          <hr />
          Thanks! We will be in touch soon!
        </div>
        <div *ngIf="!birthdayPlanetSubmitted" class="notify-form-group">
          <input
            id="birthdayPlanetInput"
            type="text"
            [(ngModel)]="birthdayPlanetNotifyEmail"
            placeholder="jane@doe.com"
            (tap)="focusInput($event)"
          />
          <button
            style="padding-top: 5px; padding-bottom: 5px"
            (click)="birthdayPlanetNotify()"
            (tap)="birthdayPlanetNotify()"
          >
            Notify Me
          </button>
          <div class="d-flex flex-wrap my-3 btn-container">
            <!-- <button class="secondary my-2 mx-2" disabled>Button 2</button> -->
          </div>
        </div>
      </div>

      <!-- Toys Planet -->
      <div *ngIf="showing === 'toys-planet'">
        <h1>Toys Planet</h1>
        <p>
          Toys are the most important assets in Mintedverse and teddies are intentional about the kind of toys they
          create—teddies believe that toys shape kids and kids shape the future.
        </p>
        <p>
          Toys are the first asset that kids own, and teddies work hard to expose them to the best craftsmanship and
          quality in the Mintedverse.
        </p>
        <p>Mintedverse toys are rooted in minimalism, sustainability, and quality.</p>
        <p class="bold highlight">🧸 Opening 2023 🧸</p>
        <p>When it opens, all teddies will be able to visit and start collecting limited-edition toys!</p>
        <div *ngIf="toysPlanetSubmitted">
          <hr />
          Thanks! We will be in touch soon!
        </div>
        <div *ngIf="!toysPlanetSubmitted" class="notify-form-group">
          <input
            id="toysPlanetInput"
            type="text"
            [(ngModel)]="toysPlanetNotifyEmail"
            placeholder="jane@doe.com"
            (tap)="focusInput($event)"
          />
          <button style="padding-top: 5px; padding-bottom: 5px" (click)="toysPlanetNotify()" (tap)="toysPlanetNotify()">
            Notify Me
          </button>
          <div class="d-flex flex-wrap my-3 btn-container">
            <!-- <button class="secondary my-2 mx-2" disabled>Button 2</button> -->
          </div>
        </div>
      </div>

      <!-- Fashion Planet -->
      <div *ngIf="showing === 'fashion-planet'">
        <h1>Fashion Planet</h1>
        <p>
          The lines between fashion, art, and space gear are blurred in the Mintedverse. The legend goes that teddies
          dressed up so fancy for their NFT photoshoot that they started a space fashion trend.
        </p>
        <p>Now they are working on a sustainable clothing line starting with the hoodie.</p>
        <p><a class="my-2 mx-2" (click)="goToStore()" (tap)="goToStore()">Click here</a>to see the hoodie.</p>
        <p>More delightful merch is in the works!</p>
        <div *ngIf="fashionPlanetSubmitted">
          <hr />
          Thanks! We will be in touch soon!
        </div>
        <div *ngIf="!fashionPlanetSubmitted" class="notify-form-group">
          <input
            id="fashionPlanetInput"
            type="text"
            [(ngModel)]="fashionPlanetNotifyEmail"
            placeholder="jane@doe.com"
            (tap)="focusInput($event)"
          />
          <button
            style="padding-top: 5px; padding-bottom: 5px"
            (click)="fashionPlanetNotify()"
            (tap)="fashionPlanetNotify()"
          >
            Notify Me
          </button>
          <div class="d-flex flex-wrap my-3 btn-container">
            <!-- <button class="secondary my-2 mx-2" disabled>Button 2</button> -->
          </div>
        </div>
      </div>

      <!-- OG Planet -->
      <div *ngIf="showing === 'og-planet'">
        <h1>OG Planet</h1>
        <p>The VIP (OG) drop of the first 100 teddies was released on Dec 2021 by invitation only.</p>
        <p>
          OG teddy holders received a gold placeholder teddy until they worked with our artist to design their custom
          teddy.
        </p>
        <p>
          Many of the OG teddies have been revealed and some still in the works-this design process takes time but the
          end result will be an epic OG collection owned by an amazing group of human beings.
        </p>
        <div class="d-flex flex-wrap my-3 btn-container">
          <button class="my-2 mx-2" (click)="goToOGSeriesCatalog()" (tap)="goToOGSeriesCatalog()">
            View Collection
          </button>
          <button
            class="secondary my-2 mx-2"
            (click)="showPopup.emit('og-council')"
            (tap)="showPopup.emit('og-council')"
          >
            OG Council
          </button>
        </div>
      </div>

      <!-- Series 1 Planet -->
      <div *ngIf="showing === 'series-1-planet'">
        <h1>Series 1 Planet</h1>
        <p>
          In Jan 2022, Minted Teddy introduced the NFT world to 1,000 adorable 3D teddies with our first public NFT
          launch.
        </p>
        <p>It was an an instant hit and sold out in a couple of hours with almost no marketing.</p>
        <p>Claudio Bellini designed the collection over several months of intensely focused work.</p>
        <p>
          It is the first-of-its-kind (and still the only one to our knowledge) generative art project where every pixel
          is procedurally enhanced and every part of each teddy unique. Minted Teddy Series 1 is a timeless
          breathtakingly beautiful art collection.
        </p>
        <div class="d-flex flex-wrap my-3 btn-container">
          <button class="my-2 mx-2" (click)="goToSeries1Catalog()" (tap)="goToSeries1Catalog()">View Collection</button>
          <button class="secondary my-2 mx-2" (click)="showVideo.emit('series-1')" (tap)="showVideo.emit('series-1')">
            See Series 1 Video
          </button>
        </div>
      </div>

      <!-- Explorers Planet -->
      <div *ngIf="showing === 'next-gen-planet'">
        <h1>Explorers Planet</h1>
        <p>
          The Explorers collection of 2,500 teddies is a culmination of a one-year design experiment by Claudio Bellini
          and guest artists. The result is a high-quality profile picture with a matching metaverse-ready 3D avatar.
        </p>
        <p>
          Explorer teddies are equipped for space adventures with space suits, helmets, and more than 20 types of space
          flight gear.
        </p>
        <p>
          Teddies will be randomly beamed to one of the 50 planets at the mint, setting in motion a co-creative
          adventure to develop the planets and design a gen3 teddy collection.
        </p>
        <div class="d-flex flex-wrap my-3 btn-container">
          <button class="my-2 mx-2" (click)="goToNextGenPreview()" (tap)="goToNextGenPreview()">Sneak Peek</button>
          <button class="secondary my-2 mx-2" (click)="showVideo.emit('next-gen')" (tap)="showVideo.emit('next-gen')">
            See Explorers Video
          </button>
        </div>
      </div>

      <!-- MoonCats Moon -->
      <div *ngIf="showing === 'mooncats-moon'">
        <h1>MoonCats Teddies</h1>
        <p>We are extremelly excited to collaborate with MoonCats to create MoonCat-themed teddies in 3D!</p>
        <p>
          MoonCats is an original NFT launched in 2017 and helped pioneer on-chain generation, fair distribution, and
          user customization.
        </p>
        <p class="bold highlight">Cats + teddies = cuteness overload!</p>
        <p>
          111 MoonCats teddies will be included in our Explorers drop including a rare one. 100 of these are reserved
          for MoonCats community and the rest will be minted by random lucky minters.
        </p>
        <div class="d-flex flex-wrap my-3 btn-container">
          <button class="my-2 mx-2" (click)="goToMoonCatsPreview()" (tap)="goToMoonCatsPreview()">Sneak Peek</button>
          <button class="secondary my-2 mx-2" (click)="showVideo.emit('next-gen')" (tap)="showVideo.emit('next-gen')">
            See Explorers Video
          </button>
        </div>
      </div>

      <!-- Theodoru Moon -->
      <div *ngIf="showing === 'theodoru-moon'">
        <h1>Theodoru x Minted Teddy</h1>
        <p>Theodoru is a Neo Pop Artist, 3D Addict, Art Toy Designer and art lover from Vienna.</p>
        <p>For the Explorers drop, Theodoru has designed a mini collection of 12 beautiful Minted Teddies.</p>
        <p>
          Working with Theodoru is a dream come true for Minted Teddy team and now 12 random lucky buyers will get the
          opportunity to own one of these rare Minted Teddies.
        </p>
        <div class="d-flex flex-wrap my-3 btn-container">
          <button class="my-2 mx-2" (click)="goToTheodoruPreview()" (tap)="goToTheodoruPreview()">Sneak Peek</button>
          <button class="secondary my-2 mx-2" (click)="showVideo.emit('next-gen')" (tap)="showVideo.emit('next-gen')">
            See Explorers Video
          </button>
        </div>
      </div>

      <!-- Brenda Chen Moon -->
      <div *ngIf="showing === 'brenda-chen-moon'">
        <h1>Brenda Chen x Minted Teddy</h1>
        <p>
          Brenda Chen is an immersive media and XR artist who directed Justin Bieber's & John Legend's virtual concerts.
        </p>
        <p>
          The "Furry Festival" mini-collection of five rare teddies is included in the upcoming Explorers drop for five
          lucky minters to draw.
        </p>
        <div class="d-flex flex-wrap my-3 btn-container">
          <button class="my-2 mx-2" (click)="goToBrendaChenPreview()" (tap)="goToBrendaChenPreview()">
            Sneak Peek
          </button>
          <button class="secondary my-2 mx-2" (click)="showVideo.emit('next-gen')" (tap)="showVideo.emit('next-gen')">
            See Explorers Video
          </button>
        </div>
      </div>

      <!-- William Willoughby III Moon -->
      <div *ngIf="showing === 'william-willoughby-iii-moon'">
        <h1>William Willoughby III x Minted Teddy</h1>
        <p>
          Minted Teddy collab with William Willoughby III, one of the most promising 3D digital artists who amassed a
          huge following with his unmistakable blend of surrealism, futurism, crypto art, and digital landscapes.
        </p>
        <p>
          This mini collection of four unique teddies is a part of the OG minted teddy collection, the first 100 teddies
          minted and custom designed for early Minted Teddy collectors.
        </p>
        <div class="d-flex flex-wrap my-3 btn-container">
          <button class="my-2 mx-2" (click)="goToWilliamWilloughbyPreview()" (tap)="goToWilliamWilloughbyPreview()">
            View Collection
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Close -->
  <div class="close-button" (click)="closeDetails.emit()" (tap)="closeDetails.emit()"></div>
</div>
