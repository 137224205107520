import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { estimateFees } from '@mycrypto/gas-estimation';
import { ContractsService } from 'src/app/services/contracts.service';
import { IpfsService } from 'src/app/services/ipfs.service';
import { Web3Service } from 'src/app/services/web3.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-drop-schedule',
  templateUrl: './drop-schedule.component.html',
  styleUrls: ['./drop-schedule.component.sass']
})
export class DropScheduleComponent implements OnInit {
  selectedVoucher: any;
  minting: boolean = false;
  loadingVoucher: boolean = true;
  noneAvailable: boolean = true;
  public processing: boolean = false;
  video: string = '';

  constructor(private router: Router, public web3Service: Web3Service, private _activatedRoute: ActivatedRoute, private contractService: ContractsService) { }

  async ngOnInit() {
    this.loadingVoucher = false;
    await this.web3Service.getAccounts();
    /*
    this.loadingVoucher = true;
    try {
      const token = await this.contractService.getNextVoucher(environment.contractId, "1");
      this.selectedVoucher = JSON.parse(token.Voucher);
    } catch (ex) {
      this.noneAvailable = true;
    } finally {
      this.loadingVoucher = false;
    }
    */
  }

  ngAfterViewChecked(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  async connectWallet() {
    await this.web3Service.connectWallet();
  }

  async reserveTeddy() {
    await this.router.navigateByUrl('/reserve/2');
  }

  async getVoucher(contractId: string, walletAddress: string) {
    const voucher = await this.contractService.getNewVoucherByWalletAddress(contractId, walletAddress);
    return voucher;
  }

  async mintVoucher() {
    const voucherObject = await this.getVoucher(environment.contractId, this.web3Service.accounts[0]);
    if (!voucherObject) {
      throw new Error('Could not mint this teddy.  Please try again in 10 minutes.');
    }
    this.selectedVoucher = JSON.parse(voucherObject.Voucher);
    if (this.selectedVoucher) {
      this.minting = true;
      const contract = await this.contractService.getContract(environment.contractId);
      await this.web3Service.getAccounts();
      const selectedContract = new this.web3Service.web3.eth.Contract(JSON.parse(contract.Contract).abi as any, contract.Address);

      const nonce = await this.web3Service.web3.eth.getTransactionCount(contract.Address, 'latest'); // get latest nonce
      const { maxFeePerGas, maxPriorityFeePerGas } = await estimateFees(environment.infuraUrl);

      try {
        const gasEstimate = await this.web3Service.web3.eth.estimateGas({
          "from"      : this.web3Service.accounts[0],
          "nonce"     : nonce,
          "to"        : contract.Address,
          "data"      : selectedContract.methods.mintNFT(this.web3Service.accounts[0], this.selectedVoucher).encodeABI(),
          "value"     : this.selectedVoucher.initialPrice
        })

        // Create the transaction
        const tx = {
          'from':this.web3Service.accounts[0],
          'to': contract.Address,
          'nonce': nonce,
          'gas': gasEstimate,
          'maxPriorityFeePerGas': maxPriorityFeePerGas.toString(),
          'value' : this.selectedVoucher.initialPrice,
          'data': selectedContract.methods.mintNFT(this.web3Service.accounts[0], this.selectedVoucher).encodeABI()
        };

        await this.web3Service.web3.eth.sendTransaction(tx)
        await this.contractService.markMinted(environment.contractId, voucherObject.Id, this.web3Service.accounts[0]);
        this.router.navigateByUrl('/token/' + this.selectedVoucher.tokenId);
        this.minting = false;
      } catch (ex) {
        this.minting = false;
        alert(ex.message.split('\n')[0]);
      }
    }
  }

  async goToGeneration1() {
    await this.router.navigateByUrl('/mint/1');
  }

  async goToGeneration2() {
    await this.router.navigateByUrl('/mint/2');
  }

  showVideo(string: string) {
    this.video = string;
  }

  hideVideo() {
    this.video = '';
  }
}
