<header-component></header-component>

<div id="FAQ" class="container py-5">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-12 col-md-10 pb-5">
      <h1 class="text-center mt-5 mb-4">FAQ</h1>
      <ngb-accordion [closeOthers]="true" activeIds="question-1">
        <ngb-panel id="question-1" title="What IP Rights do I have?">
          <ng-template ngbPanelContent>
            Each and every Minted Teddy has been MINTangible™ certified, which means that the Minted Teddy License Terms
            are digitally bound directly to them on-chain and cannot be taken away from you. You get personal and
            commercial rights to use the Art in your NFT, except that you don't have the right to create any physical
            versions of the Minted Teddy depicted in the Art. This means no 3D modeling, manufacturing, marketing, or
            sale of any figurines, toys, dolls, plush toys, or anything else that includes or represents the Art. The
            certified Minted Teddy License Terms contain all the important details and are the ultimate authority when
            it comes to exactly what IP rights you have. You can access them
            <a href="https://rightsregistry.xyz/ethereum/0x69a04d373e3e2db13027e9d584eb8203d46a86c7" target="_blank"
              >right here</a
            >.
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</div>

<footer-component></footer-component>
