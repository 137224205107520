<div id="LandingComponent" class="portal-app">
  <div class="d-flex align-items-center justify-content-center content-container">
    <iframe src="https://player.vimeo.com/video/782825795?autoplay=1&loop=1&autopause=0&muted=1&background=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    <div class="d-flex w-100 justify-content-center button-container">
      <button class="secondary">Certificate of Authenticity</button>
    </div>
  </div>

  <div class="px-3 text-area">
    <p class="reversed text-center">
      Link your physical toy to your digital wallet for quicker access to your digital avatar(s).
    </p>
  </div>

  <div class="footer">
    <button (click)="goToChooseTeddy()">Connect Wallet</button>
  </div>

  <script src="https://player.vimeo.com/api/player.js"></script>
</div>
