<header-component></header-component>

<div id="PlanetGuardiansComponent">
  <h1 class="visually-hidden">Become a Planet Guardian</h1>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-5 video-group-container">
        <div
          class="d-flex flex-wrap align-items-center justify-content-start justify-content-md-end video-group p-3 px-md-0 py-md-5"
        >
          <app-inline-video [showing]="'birthday-planet'"></app-inline-video>
          <app-inline-video [showing]="'music-planet'"></app-inline-video>
          <app-inline-video [showing]="'toys-planet'"></app-inline-video>
          <app-inline-video [showing]="'fashion-planet'"></app-inline-video>
        </div>
      </div>
      <div class="col-12 col-md-7 py-3 py-md-5 px-4 px-md-5 d-flex flex-column justify-content-center">
        <h1 class="mb-4">Become a Planet Guardian</h1>
        <h2 class="mb-4">A Once-In-A-Lifetime Opportunity for Dreamers and Visionaries</h2>
        <p class="reversed">
          Use a Minted Teddy planet as a platform to kickstart a product, movement, or for existing brands to experiment
          in Web3. Mint and design your very own Minted Teddy planet with an overarching theme and purpose. Write your
          own origin story for your planet and explain why it matters for posterity. Include lessons and words of
          encouragement to leave behind for future generations.
        </p>
        <p class="reversed mb-4">
          Prepare side quests for teddies who visit your planet, and missions for your citizen teddies. This digital
          world is your oyster!
        </p>
        <button (click)="goToMint()" disabled class="align-self-start mb-5 mb-md-3">Mint</button>
      </div>
    </div>
    <div class="row how-it-works">
      <div class="col-md-1"></div>
      <div class="col-12 col-md-6 py-3 py-md-5 px-4 px-md-5">
        <h1 class="mb-3">Ready, Set, GO</h1>
        <p class="reversed">
          No prior technical knowledge is necessary. The Minted Teddy team will work with you to bring the experience to
          life. You may also choose to bring your own team and artist to build this experience, using our technical team
          for guidance only.
        </p>
        <p class="reversed">
          Once you've completed the planet creation, you will be rewarded with 150 blank teddies to design as your own
          mini themed Minted Teddy collection.
        </p>
        <p class="reversed mb-4">
          Need more info? DM us and ask all your burning questions. Excited to grab a planet before they're gone? Mint
          one first and we can work out the details with you afterwards.
        </p>
        <button (click)="goToMint()" disabled>Mint</button>
      </div>
      <div class="col-12 col-md-5 p-5 d-flex justify-content-center">
        <app-inline-video [showing]="'music-planet-teaser'"></app-inline-video>
      </div>
    </div>
  </div>
</div>

<footer-component></footer-component>
