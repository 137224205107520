<header-component></header-component>

<div id="mintComponent">
  <div class="minted-bg">
    <h1 class="font-300 text-uppercase font-400 color-gradient-pale font-opacity">Minted Teddy</h1>
  </div>

  <div *ngIf="processing" class="loading-container">
    <div class="spinner-border" role="status">
      <span class="sr-only">Processing...</span>
    </div>
  </div>

  <div *ngIf="!processing" class="container px-4">
    <div class="row">
      <div class="col-12">
        <h1 class="mt-5 mb-4">Mint An Explorer Teddy</h1>
        <div class="content-container">
          <!-- No Voucher Selected -->
          <div *ngIf="!loadingVoucher && !selectedVoucher">No teddies available 😢</div>

          <!-- Voucher Selected -->
          <div>
            <h2 class="mb-4">{{ numMinted }}/{{ maxSupply }} Explorer Teddies have already been minted.</h2>
            <div class="d-flex flex-column align-items-start mb-5">
              <!-- Mint Teddies -->
              <div class="d-flex align-items-center mint-quantity mb-5">
                <i class="fas fa-minus" (click)="updateQty('minus')"></i>
                <span class="text-lg mx-4">{{ mintQty }}</span>
                <i class="fas fa-plus" (click)="updateQty('add')"></i>
              </div>
              <div class="d-flex flex-wrap align-items-start button-group">
                <!-- Metamask Button -->
                <div class="d-flex flex-column align-items-center mb-3 metamask-btn">
                  <button
                    class="secondary"
                    [disabled]="!(web3Service.accounts && web3Service.accounts.length > 0) || minting"
                    (click)="mintTeddies(1)"
                  >
                    <span *ngIf="minting">Minting...</span>
                    <span *ngIf="!minting">Buy with Metamask</span>
                  </button>
                  <div
                    style="color: #888888; font-size: 11px"
                    *ngIf="!(web3Service.accounts && web3Service.accounts.length > 0)"
                  >
                    Connect your wallet.
                  </div>
                </div>
                <!-- Crossmint Button -->
                <div *ngIf="mintConfig" class="mb-3">
                  <crossmint-pay-button
                    clientId="8c70860c-bd9f-4695-a358-33438b017023"
                    [mintConfig]="mintConfig"
                    environment="staging"
                    class="my-crossmint-button"
                  ></crossmint-pay-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer-component></footer-component>
