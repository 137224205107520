<header-component></header-component>

<div id="tokenComponent">
  <h1 class="visually-hidden">{{ tokenData.name }}</h1>
  <div *ngIf="loading" class="loading-container">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="!loading && tokenData" class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="mt-4 mt-lg-5 mb-4">{{ tokenData.name }}</h1>
        <div class="mb-5 nft">
          <div class="image" style="background-image: url({{ tokenData.image }})"></div>
          <div class="details">
            <p class="mb-4">{{ tokenData.description }}</p>
            <div class="attributes">
              <p *ngFor="let attribute of tokenData.attributes">{{ attribute.trait_type }}: {{ attribute.value }}</p>
            </div>
            <div>
              <p><a [href]="tokenData.opensea" target="_blank">View on OpenSea</a></p>
            </div>
            <div *ngIf="!sent && owned && tokenData.series === '1' && tokenData.attributes.length <= 1">
              <table class="customize-form">
                <tr>
                  <td>Your Name:</td>
                  <td><input type="text" [(ngModel)]="customizeName" placeholder="Jane Doe" /></td>
                </tr>
                <tr>
                  <td>Your Email:</td>
                  <td><input type="text" [(ngModel)]="customizeEmail" placeholder="jane@doe.com" /></td>
                </tr>
              </table>
              <p>
                <button *ngIf="!sent" [disabled]="sending" (click)="customizeTeddy()">
                  <span *ngIf="sending">Sending...</span><span *ngIf="!sending">Start Customizing</span>
                </button>
              </p>
            </div>
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer-component></footer-component>
