import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-popup-dialog-box',
  templateUrl: './popup-dialog-box.component.html',
  styleUrls: ['./popup-dialog-box.component.sass']
})
export class PopupDialogBoxComponent implements OnInit {
  @Input() popupContent: string = '';
  @Output() hidePopup = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}
