import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IMetadata } from '../interfaces/IMetadata';

@Injectable({
  providedIn: 'root'
})
export class IpfsService {

  constructor(private http: HttpClient) { }

  async getMetadata(ipfsHash: string): Promise<any> {
    ipfsHash = ipfsHash.replace('ipfs://', '');
    return this.http.get<IMetadata>(`${environment.ipfsUrl}/${ipfsHash}`).toPromise();
  }
}
