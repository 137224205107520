import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { create } from 'pinch-zoom-pan';

@Component({
  selector: 'app-quadrant',
  templateUrl: './quadrant.component.html',
  styleUrls: ['./quadrant.component.sass']
})
export class QuadrantComponent implements OnInit {
  quadrant: string = '';
  viewing: string = '';
  showing: string = '';
  transition: string = '';
  video: string = '';
  pathname: string = '';
  popup: string = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.pathname = window.location.pathname;

    const panEl = document.getElementById('quadrantComponent');
    if (panEl !== null) {
      create({ element: panEl });
    }
  }

  selectPlanet(planet: string) {
    // console.log(planet)
    this.showing = planet;
    this.transition = 'in';
  }

  showVideo(video: string) {
    // console.log(video)
    this.video = video;
  }

  showPopup(popup: string) {
    this.popup = popup;
  }

  hideVideo() {
    // console.log('hideVideo()')
    this.video = '';
  }

  hidePopup() {
    // console.log('hidePopup()')
    this.popup = '';
  }

  closeDetails() {
    this.transition = 'out';
    setTimeout(() => {
      this.showing = '';
    }, 200);
  }

  async goToMintedverse() {
    await this.router.navigateByUrl('/mintedverse');
  }
}
