import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { environment } from 'src/environments/environment';
import { estimateFees } from '@mycrypto/gas-estimation';
import { Web3Service } from 'src/app/services/web3.service';
import PlanetsAbi from '../../../assets/abis/planetsAbi.json';
import BigNumber from 'bignumber.js';

@Component({
  selector: 'app-mint-planet-invite',
  templateUrl: './mint-planet-invite.component.html',
  styleUrls: ['./mint-planet-invite.component.sass'],
})
export class MintPlanetInviteComponent implements OnInit {
  selectedVoucher: any;
  minting: boolean = false;
  loadingVoucher: boolean = true;
  numMinted: number = 0;
  maxSupply: number = 0;
  mintPrice: number = 0;
  remaining: number = 0;
  mintQty: number = 1;
  maxMintQty: number = 1;
  planetId: number = 1;

  mintConfig: any = { type: 'erc-721', totalPrice: this.mintPrice, planetId: this.planetId };

  public processing: boolean = false;
  constructor(
    private router: Router,
    public web3Service: Web3Service,
    private _activatedRoute: ActivatedRoute,
    private contractService: ContractsService
  ) {}

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(async (parameter) => {
      this.numMinted = 0;
      this.maxSupply = 50;

      const planetsAbi = PlanetsAbi;
      const selectedContract = new this.web3Service.web3.eth.Contract(
        planetsAbi.abi as any,
        environment.planetContract
      );

      this.numMinted = await selectedContract.methods.totalSupply().call();
      this.maxSupply = await selectedContract.methods.MAX_COLLECTION_SIZE().call();
      this.mintPrice = await selectedContract.methods.MINT_PRICE().call();
      console.log(this.mintPrice);
      this.mintConfig.totalPrice = String(
        Number((BigInt(this.mintPrice) * BigInt(this.mintQty)) / BigInt(10000000000000000)) / 100
      );
      console.log(this.mintConfig);
    });
  }

  ngAfterContentInit(): void {
    this.remaining = this.maxSupply - this.numMinted;
  }

  setPlanetId(planetId: string) {
    this.planetId = Number(planetId);
    this.mintConfig.planetId = this.planetId;
    console.log(this.mintConfig);
  }

  async mintPlanet() {
    try {
      await this.web3Service.getAccounts();

      this.minting = true;
      const planetsAbi = PlanetsAbi;
      const selectedContract = new this.web3Service.web3.eth.Contract(
        planetsAbi.abi as any,
        environment.planetContract
      );
      const nonce = await this.web3Service.web3.eth.getTransactionCount(environment.planetContract, 'latest'); // get latest nonce
      const { maxFeePerGas, maxPriorityFeePerGas } = await estimateFees(environment.infuraUrl);
      console.log(String(BigInt(this.mintPrice) * BigInt(10)));
      try {
        const gasEstimate = await this.web3Service.web3.eth.estimateGas({
          from: this.web3Service.accounts[0],
          nonce: nonce,
          to: environment.planetContract,
          data: selectedContract.methods.mintPlanetNV(this.web3Service.accounts[0], this.planetId).encodeABI(),
          value: String(BigInt(this.mintPrice) * BigInt(1)),
        });

        // Create the transaction
        const tx = {
          from: this.web3Service.accounts[0],
          to: environment.planetContract,
          nonce: nonce,
          gas: gasEstimate,
          maxFeePerGas: maxFeePerGas.toString(),
          value: String(BigInt(this.mintPrice) * BigInt(1)),
          data: selectedContract.methods.mintPlanetNV(this.web3Service.accounts[0], this.planetId).encodeABI(),
        };

        await this.web3Service.web3.eth.sendTransaction(tx);
        this.router.navigateByUrl('/mint/success');
      } catch (ex: any) {
        alert(ex.message);
      }
    } catch (ex: any) {
      alert(ex.message);
    }

    this.minting = false;
  }

  async connectWallet() {
    await this.web3Service.connectWallet();
  }
}
