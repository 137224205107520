<div class="backdrop" [ngClass]="{ 'super-zoom': viewing === 'quadrant-6' }"></div>
<div class="planets" [ngClass]="{ 'super-zoom': viewing === 'quadrant-6' }">
  <h1 class="visually-hidden">Mintedverse Qudrant 6</h1>
  <div
    class="planet blue-planet blue-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Cawuoma"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet pink-planet pink-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Prosperaa"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet yellow-planet yellow-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Hapayaya"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Lovusoma"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet blue-planet blue-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Nofomorea"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet pink-planet pink-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Boreapemoa"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet yellow-planet yellow-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Doodleloka"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Kodaroria"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet blue-planet blue-planet-3"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Zokomakera"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-3"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Crydibutaka"
    aria-label="Theme Planet"
  ></div>
</div>
<div class="overlay"></div>
