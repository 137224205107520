<header-component></header-component>

<div id="noaccessComponent" class="container-fluid">
  <div class='club-banner'></div>
  <div class="row px-3 content-area">
    <div class="col-12 col-sm-6 px-5 left-column">
      <h2 class="mt-5 mb-3 text-yellow">Welcome to The Next Digital Frontier</h2>
      <p class="reversed">Owning a Minted Teddy goes far beyond simply owning a valuable piece of digital artwork verifiable on the distributed network. We are building an unbridled “phygital” future with 3D avatars, premium designer toys, and a whole new digital VR experience — The Mintedverse!</p>
      <div class="text-block">
        <p class="notification reversed">You must own a teddy NFT in order to access the Mintedverse.</p>
      </div>
      <p>Get a <span (click)="goToCatalog()" class="link">Minted Teddy NFT</span> to join us for the ride! 🚀</p>
    </div>
    <div class="col-12 col-sm-6 px-5 pl-sm-0 roadmap right-column">
      <app-roadmap-accordion></app-roadmap-accordion>
    </div>
  </div>
</div>
<footer-component></footer-component>
