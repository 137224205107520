<header-component></header-component>

<div id="mintPlanetInviteComponent">
  <div class="minted-bg">
    <h1 class="font-300 text-uppercase font-400 color-gradient-pale font-opacity">Minted Teddy</h1>
  </div>

  <div *ngIf="processing" class="loading-container">
    <div class="spinner-border" role="status">
      <span class="sr-only">Processing...</span>
    </div>
  </div>

  <div *ngIf="!processing" class="container px-4">
    <div class="row">
      <div class="col-12">
        <h1 class="mt-5 mb-4">Mint A Planet</h1>
        <div class="content-container">
          <!-- No Voucher Selected -->
          <div *ngIf="!loadingVoucher && !selectedVoucher">No teddies available 😢</div>

          <!-- Voucher Selected -->
          <div>
            <div style="display: flex; flex-direction: column; align-items: center">
              <!-- Mint Teddies -->
              <div class="d-flex align-items-center mint-quantity my-4">
                <input type="text" [ngModel]="planetId" (ngModelChange)="setPlanetId($event)" class="text-lg mx-4" />
              </div>
              <div style="display: flex; flex-direction: row">
                <div style="padding: 10px; display: flex; flex-direction: column; align-items: center">
                  <button
                    class="secondary"
                    [disabled]="!(web3Service.accounts && web3Service.accounts.length > 0) || minting"
                    (click)="mintPlanet()"
                  >
                    <span *ngIf="minting">Minting...</span><span *ngIf="!minting">Buy with Metamask</span>
                  </button>
                  <div
                    style="color: #888888; font-size: 11px"
                    *ngIf="!(web3Service.accounts && web3Service.accounts.length > 0)"
                  >
                    Connect your wallet.
                  </div>
                </div>
                <div
                  style="padding: 10px"
                  *ngIf="false && mintConfig && web3Service.accounts && web3Service.accounts.length > 0"
                >
                  <crossmint-pay-button
                    clientId="36fd4080-eb16-4144-981c-0802b1618126"
                    [mintConfig]="mintConfig"
                    [mintTo]="web3Service.accounts[0]"
                    class="my-crossmint-button"
                  ></crossmint-pay-button>
                </div>
                <div
                  style="padding: 10px"
                  *ngIf="false && mintConfig && !(web3Service.accounts && web3Service.accounts.length > 0)"
                >
                  <crossmint-pay-button
                    clientId="778b65ea-8c53-4f08-a785-f63c5cbc2843"
                    [mintConfig]="mintConfig"
                    environment="staging"
                    class="my-crossmint-button"
                  ></crossmint-pay-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer-component></footer-component>
</div>
