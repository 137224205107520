import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-show-video',
  templateUrl: './show-video.component.html',
  styleUrls: ['./show-video.component.sass']
})
export class ShowVideoComponent implements OnInit {
  @Input() video: string = '';
  @Output() hideVideo = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {

  }

}
