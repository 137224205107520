import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { environment } from 'src/environments/environment';
import { estimateFees } from '@mycrypto/gas-estimation';
import { Web3Service } from 'src/app/services/web3.service';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.sass']
})
export class RedeemComponent implements OnInit {

  selectedVoucher: any;
  minting: boolean = false;
  loadingVoucher: boolean = true;
  constructor(private router: Router, public web3Service: Web3Service, private _activatedRoute: ActivatedRoute, private contractService: ContractsService) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(async parameter => {
      await this.web3Service.getAccounts();
      this.loadingVoucher = true;
      
      if (!parameter.voucherId) {
        //get voucher by wallet address
        try {
          const token = await this.contractService.getVoucherByWalletAddress(environment.contractId, this.web3Service.accounts[0]);
          this.selectedVoucher = JSON.parse(token.Voucher);
        } catch (ex) {

        } finally {
          this.loadingVoucher = false;
        }
      } else {
        try {
          const token = await this.contractService.getVoucher(environment.contractId, parameter.voucherId);
          this.selectedVoucher = JSON.parse(token.Voucher);
        } catch (ex) {
  
        } finally {
          this.loadingVoucher = false;
        }
      }
    })
  }

  async mintVoucher() {
    this.minting = true;
    const contract = await this.contractService.getContract(environment.contractId);
    await this.web3Service.getAccounts();
    const selectedContract = new this.web3Service.web3.eth.Contract(JSON.parse(contract.Contract).abi as any, contract.Address);
    
    const nonce = await this.web3Service.web3.eth.getTransactionCount(contract.Address, 'latest'); // get latest nonce
    const { maxFeePerGas, maxPriorityFeePerGas } = await estimateFees(environment.infuraUrl);

    try {
      const gasEstimate = await this.web3Service.web3.eth.estimateGas({
        "from"      : this.web3Service.accounts[0],       
        "nonce"     : nonce, 
        "to"        : contract.Address,     
        "data"      : selectedContract.methods.mintNFT(this.web3Service.accounts[0], this.selectedVoucher).encodeABI(),
        "value"     : this.selectedVoucher.initialPrice
      })
      
      // Create the transaction
      const tx = {
        'from':this.web3Service.accounts[0],
        'to': contract.Address,
        'nonce': nonce,
        'gas': gasEstimate, 
        'maxPriorityFeePerGas': maxPriorityFeePerGas.toString(),
        'value' : this.selectedVoucher.initialPrice,
        'data': selectedContract.methods.mintNFT(this.web3Service.accounts[0], this.selectedVoucher).encodeABI()
      };

      const response = await this.web3Service.web3.eth.sendTransaction(tx);
      this.router.navigateByUrl('/token/' + this.selectedVoucher.tokenId);
      this.minting = false;
    } catch (ex) {
      this.minting = false;
      alert(ex.message.split('\n')[0]);
    }
  }

  async connectWallet() {
    await this.web3Service.connectWallet();
  }


}
