<div id="homeComponent" *ngIf="!splashscreen" [ngClass]="{ 'appear-in': transitionSplash === true }">
  <h1 class="visually-hidden">Home of the Minted Teddy</h1>
  <div class="d-flex align-items-center justify-content-center container-fluid reversed">
    <div class="p-0 home-bg">
      <div class="ellipse-locations desktop">
        <img src="/assets/img/ellipse1.png" class="ellipse1" />
        <img src="/assets/img/ellipse2.png" class="ellipse2" />
        <!-- <img src="/assets/img/ellipse3.png" class="ellipse3" />
        <img src="/assets/img/ellipse4.png" class="ellipse4" /> -->
      </div>
    </div>

    <div class="logo">
      <div class="row m-0 p-0 position-relative">
        <div class="d-flex justify-content-center align-items-center">
          <img src="/assets/img/logo.svg" class="desktop" (click)="goToMintedverse()" />
          <img src="/assets/img/logo-mb.svg" class="mobile" (click)="goToMintedverse()" />
        </div>
      </div>
    </div>

    <div class="minted-bg">
      <h1 class="font-300 text-uppercase font-400 color-gradient-pale font-opacity">Minted Teddy</h1>
    </div>

    <div class="row launch-pad">
      <div class="col-12 d-flex flex-wrap justify-content-around align-items-center mx-auto">
        <div class="icon-item profile-photos" (click)="showDetails('nfts')"></div>
        <div class="icon-item avatar" (click)="showDetails('avatars')"></div>
        <div class="icon-item physical-toy" (click)="showDetails('toys')"></div>
      </div>
    </div>

    <div class="row w-100 m-0 links">
      <div
        class="col-12 d-flex flex-wrap align-items-center justify-content-center justify-content-md-end px-2 px-sm-5 pt-md-5"
        [ngClass]="{ 'justify-content-between': isMobile }"
      >
        <div class="d-flex flex-wrap align-items-center justify-content-center reversed social-icons">
          <!-- Twitter -->
          <a href="https://twitter.com/mintedteddy" target="blank">
            <div class="item twitter"></div>
          </a>
          <!-- Instagram -->
          <a href="https://www.instagram.com/mintedteddy" target="blank">
            <div class="item instagram"></div>
          </a>
          <!-- Open Sea -->
          <a href="https://opensea.io/collection/mintedteddy" target="blank">
            <div class="item opensea"></div>
          </a>
          <!-- Audio: All devices -->
          <div class="item sound" [ngClass]="{ on: sound }" (click)="toggleSound()"></div>
          <audio
            *ngIf="sound"
            autoplay
            loop
            src="/assets/audio/MT_Feat.BlueKids_HoldMe_MASTER2.mp3"
            style="visibility: hidden; display: none"
          >
            Your browser does not support the <code>audio</code> element.
          </audio>
        </div>

        <!-- Audio controls: Mobile only -->
        <!-- <div *ngIf="isMobile" class="d-flex justify-content-center audio-controls">
          <audio controls src="/assets/audio/MT_Feat.BlueKids_HoldMe_MASTER2.mp3" style="margin-top:20px;">
            Your browser does not support the <code>audio</code> element.
          </audio>
        </div> -->
      </div>
    </div>
  </div>

  <div
    *ngIf="showing !== ''"
    id="homePanel"
    [ngClass]="{
      'transition-in': transition === 'in',
      'transition-out': transition === 'out',
      nfts: showing === 'nfts',
      avatars: showing === 'avatars',
      toys: showing === 'toys'
    }"
    class="container-fluid"
  >
    <div class="row">
      <div
        class="col-5 mintedverse"
        [ngClass]="{ nfts: showing === 'nfts', avatars: showing === 'avatars', toys: showing === 'toys' }"
      ></div>
      <div class="col-12 col-md-7 p-4 p-sm-5 reversed panel-content">
        <!-- NFT Profile Photos -->
        <div *ngIf="showing === 'nfts'">
          <h1>Collectible Avatars</h1>
          <p>
            Minted Teddy is more than just an NFT. It is a beautiful piece of 3D art, crafted by Italian artist Claudio
            Bellini. It comes with a physical collectible toy and a teddy avatar that will be ready for the metaverse
            when you are.
          </p>
          <p>
            Minted Teddy NFT is your key to the Mintedverse — a place where communities are built, and dreams come true.
            🚀
          </p>
          <div class="d-flex flex-wrap my-3 btn-container">
            <button class="my-2 mx-2" (click)="goToMintedverse()">Explore Mintedverse</button>
            <button class="secondary my-2 mx-2" (click)="goToNextGenPreview()">Sneak Peek</button>
          </div>
          <div class="d-flex flex-wrap teddies-wrapper">
            <div class="teddy t1"></div>
            <div class="teddy t2"></div>
            <div class="teddy t3"></div>
            <div class="teddy t4"></div>
          </div>
        </div>
        <!-- 3D Avatars -->
        <div *ngIf="showing === 'avatars'">
          <h1>Your Digital Teddy</h1>
          <p>
            Paired with the physical minted teddy toy to unlock a whole universe of possibilities for learning and play.
          </p>
          <p>Get one teddy for your family.</p>
          <p>Only 11,111 will ever be minted. 🧸</p>
          <video width="300" height="300" autoplay loop muted playsinline>
            <source src="assets/vid/Dancing0001-0392.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <p class="mt-3">Get your teddy before they're all gone!</p>
          <div class="d-flex flex-wrap my-3 btn-container">
            <button class="my-2 mx-2" (click)="goToMintedverse()">Explore Mintedverse</button>
            <button class="secondary my-2 mx-2" (click)="goToTeddyExplorers()">Mint a Teddy Explorer</button>
            <!-- <button class="secondary my-2 mx-2" (click)="goToNextGenPreview()">Sneak Peek</button> -->
          </div>
        </div>
        <!-- Physical Toys -->
        <div *ngIf="showing === 'toys'">
          <h1>Explorers Toy</h1>
          <p>Minted Teddy is a metaverse toy on a mission to become the most impactful teddy for future generations.</p>
          <p>Give your kiddo a fun toy that blurs the lines between the physical and digital worlds.</p>
          <p>
            These premium toys are balanced, articulated, and feel nice in your hand. They are equipped with NFC
            technology where you can jump right into the Mintedverse with a simple tap of your smart device. Pretty
            nifty! 📱
          </p>
          <div class="physical-toy-gif"></div>
          <p class="mt-3">Only available for Minted Teddy NFT owners.</p>
          <div class="d-flex flex-wrap my-3 btn-container">
            <button class="my-2 mx-2" (click)="goToMintedverse()">Explore Mintedverse</button>
            <button class="secondary my-2 mx-2" (click)="showTeddyInfo()">Learn More</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Close -->
    <div class="close-button" (click)="closeDetails()"></div>
  </div>
  <div *ngIf="showing !== ''" class="overlay" (click)="closeDetails()"></div>
</div>
<!-- Splashscreen Showing -->
<div *ngIf="splashscreen" class="splashscreen" [ngClass]="{ 'zoom-in': transitionSplash === true }">
  <img src="assets/img/logo-plain.svg" (click)="showContent()" />
</div>
<!-- Teddy Physical Toy Info -->
<div *ngIf="teddyInfo" class="physical-toy-wrapper d-flex align-items-center justify-content-center">
  <app-physical-toy-info (closeToyInfo)="closeToyInfo()" id="physicalToyComponentWrapper"> </app-physical-toy-info>
  <div class="overlay" (click)="closeToyInfo()"></div>
</div>
