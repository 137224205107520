import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.sass'],
})
export class CatalogComponent implements OnInit {
  pathname: string = '';
  path_list: Array<string> = [];
  categories_list: Array<string> = ['/catalog/og-series', '/catalog/series1', '/catalog/nextgen'];
  q5_path_list: Array<string> = [
    '/catalog/og-series',
    '/catalog/series1',
    '/catalog/nextgen',
    '/catalog/theodoru',
    '/catalog/brendachen',
    '/catalog/mooncats',
    '/catalog/williamwilloughbyiii',
  ];
  last_path: string = '';

  constructor(private router: Router, private navigation: NavigationService) {}

  async ngOnInit() {
    const last_path_index = this.navigation.history.length - 2;
    if (last_path_index >= 0) {
      this.last_path = this.navigation.history[last_path_index];
    }

    this.q5_path_list.forEach((path) => {
      if (!this.path_list.includes(path)) {
        this.path_list.push(path);
      }
    });

    const path = window.location.pathname;
    if (path !== '/catalog') {
      // redirect to '/catalog' unless path is in q5_path_list
      var match = this.path_list.some((item) => {
        return path.includes(item);
      });
      if (!match) {
        await this.router.navigateByUrl('/catalog/og-series');
      }
    } else {
      await this.router.navigateByUrl('/catalog/og-series');
    }

    this.pathname = path;
  }

  ngAfterViewInit(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  goToOpenSea(token: any) {
    window.open(token.openSeaUrl, '_blank');
  }

  async goToQuadrant1() {
    await this.router.navigateByUrl('/mintedverse/quadrant-1');
  }

  async goToOGSeries() {
    await this.router.navigateByUrl('/catalog/og-series');
  }

  async goToSeries1() {
    await this.router.navigateByUrl('/catalog/series1');
  }

  async goToNextGen() {
    await this.router.navigateByUrl('/catalog/nextgen');
  }

  async goToHome() {
    await this.router.navigateByUrl('/');
  }

  async goToWilliam() {
    await this.router.navigateByUrl('/catalog/williamwilloughbyiii');
  }
}
