import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';
import WalletLink from 'walletlink';
import Web3 from 'web3';

export const WEB3 = new InjectionToken<Web3>('web3', {
  providedIn: 'root',
  factory: () => {
    try {
      const provider = ('ethereum' in window) ? (window as any).ethereum : Web3.givenProvider;
      return new Web3(provider);
    } catch (err) {
      const walletLink = new WalletLink({
        appName: "MintedTeddy",
        appLogoUrl: '',
        darkMode: false
      })
      const ethereum = walletLink.makeWeb3Provider(environment.infuraUrl, Number(environment.network));
      return new Web3(ethereum as any);
      //throw new Error('Non-Ethereum browser detected. You should consider trying Mist or MetaMask!');
    }
  }
});