import { Inject, Injectable, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import { WEB3 } from '../web3';

@Injectable({
  providedIn: 'root'
})
export class Web3Service {
  public supportsWeb3: boolean = false;
  public accounts: string[] = [];
  public selectedAccount!: string;
  public connected: boolean = false;
  public chainId!: string;
  public isMetaMask: boolean = true;

  constructor(private ngZone: NgZone, @Inject(WEB3) public web3: Web3) { 
    this.supportsWeb3 = ('ethereum' in window) ? true : false;
    if (this.supportsWeb3) {
      (window as any).ethereum.on('connect', (connectInfo: any) => {
        this.ngZone.run(async () => { await this.getAccounts() });
      });
      (window as any).ethereum.on('disconnect', (error: any) => {
        this.ngZone.run(async () => { await this.getAccounts() });
      });
      (window as any).ethereum.on('chainChanged', async (chainId: string) => {
        console.log('chain changed');
        this.chainId = chainId;
        if (String((window as any).ethereum.chainId) !== String(environment.chain)) {
          setTimeout(async () => { await (window as any).ethereum.request({method: 'wallet_switchEthereumChain', params:[{chainId: environment.chain}]}) }, 1000);
        }
      });
      (window as any).ethereum.on('accountsChanged', (accounts: string[]) => {
        this.ngZone.run(async () => { await this.getAccounts() });
      });
      (window as any).ethereum.on('message', (message: any) => {
        console.log(message);
      });

      if ((window as any).ethereum.selectedAddress) {
        this.connected = true;
        this.chainId = (window as any).ethereum.chainId;
        web3.eth.getAccounts().then((accounts:string[]) => this.accounts = accounts);
        try {
          web3.eth.transactionBlockTimeout = 500;
        } catch (ex) {
          console.log('couldnt set timeout');
        }
        this.selectedAccount = (window as any).ethereum.selectedAddress;
      } else {
        this.connected = false;
      }
      
    }
  }

  async loadConnectedStatus() {
    if (String((window as any).ethereum.chainId) === String(environment.chain)) {
      if ((window as any).ethereum.selectedAddress) {
        this.connected = true;
      } else {
        this.connected = false;
      }
    } else {
      this.connected = false;
      const response = await (window as any).ethereum.request({method: 'wallet_switchEthereumChain', params:[{chainId: environment.chain}]})
      console.log(response)
    }
  }

  async getAccounts() {
    this.accounts = await this.web3.eth.getAccounts();
    if (this.selectedAccount && this.accounts[0]?.toLowerCase() != this.selectedAccount?.toLowerCase()) {
      window.location.reload();
      return;
    }
    this.selectedAccount = this.accounts[0];
    this.isMetaMask = (this.web3?.currentProvider as any).isMetaMask === true;
    this.loadConnectedStatus();
    return this.accounts;
  }

  async connectWallet() {
    (window as any).ethereum.enable();
  }
}
