<header-component></header-component>

<div id="premintComponent">
  <div class="minted-bg">
    <h1 class="font-300 text-uppercase font-400 color-gradient-pale font-opacity">Minted Teddy</h1>
  </div>

  <div *ngIf="processing" class="loading-container">
    <div class="spinner-border" role="status">
      <span class="sr-only">Processing...</span>
    </div>
  </div>

  <div *ngIf="!processing" class="container px-4">
    <div class="row">
      <div class="col-12">
        <h1 class="mt-5 mb-4">Mint An Explorer Teddy</h1>
        <div class="content-container">
          <!-- No Voucher Selected -->
          <div *ngIf="!loadingVoucher && !selectedVoucher">No teddies available 😢</div>

          <!-- Voucher Selected -->
          <div>
            <h2 class="mb-4">{{ numMinted }}/{{ maxSupply }} Explorer Teddies have already been minted.</h2>
            <!-- Wallet not connected -->
            <div *ngIf="!(web3Service.accounts && web3Service.accounts.length > 0)">
              <p>Buuuut... to mint a teddy, you must first connect your wallet.</p>
              <p>
                <button [disabled]="minting" (click)="connectWallet()">Connect Wallet Now</button>
              </p>
            </div>
            <!-- Wallet Connected -->
            <div *ngIf="web3Service.accounts && web3Service.accounts.length > 0">
              <!-- Mint Teddies -->
              <div class="d-flex align-items-center mint-quantity my-4">
                <i class="fas fa-minus" (click)="updateQty('minus')"></i>
                <span class="text-lg mx-4">{{ mintQty }}</span>
                <i class="fas fa-plus" (click)="updateQty('add')"></i>
              </div>
              <button class="secondary" [disabled]="minting" (click)="preMint()">
                <span *ngIf="minting">Minting...</span><span *ngIf="!minting">Mint Teddies Now 🌿</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer-component></footer-component>
