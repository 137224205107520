<div class="backdrop" [ngClass]="{ 'super-zoom': viewing === 'quadrant-3' }"></div>
<div class="planets" [ngClass]="{ 'super-zoom': viewing === 'quadrant-3' }">
  <h1 class="visually-hidden">Mintedverse Qudrant 3</h1>
  <div
    class="planet blue-planet blue-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Beutilovea"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet pink-planet pink-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Smilahaha"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet yellow-planet yellow-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Modaverziza"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-1"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Birotoyoba"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet blue-planet blue-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Planuniverza"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet pink-planet pink-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Familolo"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet yellow-planet yellow-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Owesopovea"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-2"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Unimiteroka"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet purple-planet purple-planet-3"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Sucemonera"
    aria-label="Theme Planet"
  ></div>
  <div
    class="planet blue-planet blue-planet-3"
    (click)="selectPlanet.emit('gen-planet')"
    (tap)="selectPlanet.emit('gen-planet')"
    matTooltip="Kidopamaza"
    aria-label="Theme Planet"
  ></div>
</div>
<div class="overlay"></div>
