export const environment = {
    production: true,
    siteUrl: "https://club.mintedteddy.com",
    apiUrl: "https://nft-studio-api.herokuapp.com",
    ipfsUrl: "https://ipfs.mintedteddy.com/ipfs",
    contractId: "1cb11b59-b62f-4505-9f53-b1d0da07e6ab",
    infuraUrl: "https://mainnet.infura.io/v3/fd5476cbd80a4d4a8c4d7817c60e735f",
    chain: "0x1",
    network: "1",
    openSeaUrl: "https://opensea.io",
    teddiesContract: "undefined",
    planetContract: "0x267b73f0B67594A4d1bA44C34A0A2d4c0c7b6aD5",
};
