<header-component></header-component>

<div id="catalogComponent">
  <h1 class="visually-hidden">Minted Teddy Catalog</h1>
  <div class="p-0 home-bg">
    <div class="ellipse-locations desktop">
      <img src="/assets/img/ellipse1.png" class="ellipse1" />
      <img src="/assets/img/ellipse2.png" class="ellipse2" />
      <img src="/assets/img/ellipse3.png" class="ellipse3" />
      <img src="/assets/img/ellipse4.png" class="ellipse4" />
    </div>

    <div class="row m-0 p-0 position-relative">
      <div class="testimonial-view">
        <div class="minted-bg">
          <h1 class="font-300 text-uppercase font-400 color-gradient-pale font-opacity">Minted Teddy</h1>
        </div>

        <div class="teddy-gallery">
          <!-- Back to Quadrant -->
          <div *ngIf="q5_path_list.includes(pathname)" class="container-fluid">
            <div class="row">
              <div class="col-12 d-flex align-items-center flex-wrap px-sm-4 mb-4 mb-lg-0 pt-3">
                <button *ngIf="last_path === '/mintedverse/quadrant-1'" class="fade-in" (click)="goToQuadrant1()">
                  Back to Planets
                </button>
                <button *ngIf="last_path === '/'" class="fade-in" (click)="goToHome()">Back to Home</button>
                <!-- Filters -->
                <div *ngIf="categories_list.includes(pathname)" class="d-flex pt-3 pt-sm-0">
                  <div
                    class="category"
                    [ngClass]="{ current: pathname === '/catalog/og-series' }"
                    (click)="goToOGSeries()"
                  >
                    OG
                  </div>
                  <div
                    class="category"
                    [ngClass]="{ current: pathname === '/catalog/series1' }"
                    (click)="goToSeries1()"
                  >
                    Series 1
                  </div>
                  <div
                    class="category"
                    [ngClass]="{ current: pathname === '/catalog/nextgen' }"
                    (click)="goToNextGen()"
                  >
                    Explorers
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Catalogs -->
          <app-teddy-gallery *ngIf="pathname === '/catalog/og-series'"></app-teddy-gallery>
          <app-teddy-gallery-nextgen *ngIf="pathname === '/catalog/nextgen'"></app-teddy-gallery-nextgen>
          <app-teddy-gallery-series1 *ngIf="pathname === '/catalog/series1'"></app-teddy-gallery-series1>
          <app-teddy-gallery-william *ngIf="pathname === '/catalog/williamwilloughbyiii'"></app-teddy-gallery-william>
          <app-teddy-gallery-theodoru *ngIf="pathname === '/catalog/theodoru'"></app-teddy-gallery-theodoru>
          <app-teddy-gallery-brendachen *ngIf="pathname === '/catalog/brendachen'"></app-teddy-gallery-brendachen>
          <app-teddy-gallery-mooncats *ngIf="pathname === '/catalog/mooncats'"></app-teddy-gallery-mooncats>
        </div>
      </div>
    </div>
  </div>
</div>

<footer-component></footer-component>
