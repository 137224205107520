<ngb-accordion [closeOthers]="true">
  <ngb-panel id="static-1" title="PLANETS">
    <ng-template ngbPanelContent>
      Mintedverse starts as a collaborative, imaginative experience that will evolve into an immersive experience over time. Planet owners will be able to develop their own Minted Teddy story and design.
    </ng-template>
  </ngb-panel>
  <ngb-panel id="static-2" title="TOYS">
    <ng-template ngbPanelContent>
      Minted Teddy premium toys are available exclusively for Minted Teddy NFT holders. They make a great gift for any kiddo or toy collector.
    </ng-template>
  </ngb-panel>
  <ngb-panel id="static-3" title="MERCH">
    <ng-template ngbPanelContent>
      Minted Teddy merch will make you stand out and feel super comfy! Our merch will always be made from high-quality, natural materials.
    </ng-template>
  </ngb-panel>
  <ngb-panel id="static-4" title="EVENTS">
    <ng-template ngbPanelContent>
      Stay tuned for future events and exclusive parties that will be thrown for Minted Teddy NFT owners and their closest friends. Meet and hang with other future-minded, high on life, enthusiasts.
    </ng-template>
  </ngb-panel>
  <!-- <ngb-panel id="static-3" title="3D AVATAR AIRDROP">
    <ng-template ngbPanelContent>
      Your metaverse-ready 3D Minted Teddy avatar. It’s going to be sweeeeeet!
    </ng-template>
  </ngb-panel>
  <ngb-panel id="static-4" title="AI TEDDY COLLAB">
    <ng-template ngbPanelContent>
      Let’s collaborate on a digital teddy with our collective-wisdom. Minted Teddy NFT owners will be invited to pour their deepest and most inspiring thoughts to pass on to our future generations into a single teddy AI.
    </ng-template>
  </ngb-panel>
  -->
</ngb-accordion>
