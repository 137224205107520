import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IContract } from '../interfaces/IContract';
import { IVoucher } from '../interfaces/IVoucher';
import { IVoucherOption } from '../interfaces/IVoucherOption';
import { IReservationInvitation } from '../interfaces/IReservationInvitation';
import { IPreviewTokenMetadata, ITokenMetadata } from '../interfaces/ITokenMetadata';

@Injectable({
  providedIn: 'root',
})
export class ContractsService {
  constructor(private http: HttpClient) {}

  async getContract(contractId: string): Promise<IContract> {
    return this.http.get<IContract>(`${environment.apiUrl}/contracts/${contractId}`).toPromise();
  }

  async getPreviewTokens(networkName: string, collectionName: string): Promise<IPreviewTokenMetadata[]> {
    return this.http
      .get<IPreviewTokenMetadata[]>(`${environment.apiUrl}/contracts/${networkName}/${collectionName}/preview`)
      .toPromise();
  }

  async getPreviewTokenById(
    networkName: string,
    collectionName: string,
    tokenId: number
  ): Promise<IPreviewTokenMetadata> {
    return this.http
      .get<IPreviewTokenMetadata>(`${environment.apiUrl}/contracts/${networkName}/${collectionName}/preview/${tokenId}`)
      .toPromise();
  }

  async getTokensByPage(contractId: string, page: number): Promise<ITokenMetadata[]> {
    return this.http
      .get<ITokenMetadata[]>(`${environment.apiUrl}/contracts/${contractId}/tokenmetadatas?page=${page}&num=24`)
      .toPromise();
  }

  async getSeries1TokensByPage(contractId: string, page: number): Promise<ITokenMetadata[]> {
    return this.http
      .get<ITokenMetadata[]>(`${environment.apiUrl}/contracts/${contractId}/tokenmetadatas/series1?page=${page}&num=24`)
      .toPromise();
  }

  async getTokenById(contractId: string, tokenId: number): Promise<ITokenMetadata> {
    return this.http
      .get<ITokenMetadata>(`${environment.apiUrl}/contracts/${contractId}/tokenmetadatas/${tokenId}`)
      .toPromise();
  }

  async getNextVoucher(contractId: string, edition: string) {
    return this.http
      .get<IVoucher>(`${environment.apiUrl}/contracts/${contractId}/generations/${edition}/voucher`)
      .toPromise();
  }

  async getRandomVoucher(contractId: string): Promise<IVoucher> {
    return this.http.get<IVoucher>(`${environment.apiUrl}/contracts/${contractId}/vouchers`).toPromise();
  }

  async getVoucher(contractId: string, voucherId: string): Promise<IVoucher> {
    return this.http.get<IVoucher>(`${environment.apiUrl}/contracts/${contractId}/vouchers/${voucherId}`).toPromise();
  }

  async getNewVoucherByWalletAddress(contractId: string, walletAddress: string): Promise<IVoucher> {
    return this.http
      .get<IVoucher>(`${environment.apiUrl}/contracts/${contractId}/walletvouchers/${walletAddress}`)
      .toPromise();
  }

  async getVoucherByWalletAddress(contractId: string, walletAddress: string): Promise<IVoucher> {
    return this.http
      .get<IVoucher>(`${environment.apiUrl}/contracts/${contractId}/whitelist/${walletAddress}`)
      .toPromise();
  }

  async getToken(contractId: string, tokenId: string): Promise<IVoucher> {
    return this.http.get<IVoucher>(`${environment.apiUrl}/contracts/${contractId}/tokens/${tokenId}`).toPromise();
  }

  async markMinted(contractId: string, voucherId: string, walletAddress: string): Promise<{ url: string }> {
    return this.http
      .post<{ url: string }>(
        `${environment.apiUrl}/contracts/${contractId}/vouchers/${voucherId}/minted`,
        { walletAddress },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .toPromise();
  }

  async saveMetadata(contractId: string, metadata: any): Promise<{ url: string }> {
    return this.http
      .post<{ url: string }>(`${environment.apiUrl}/contracts/${contractId}/metadata`, metadata, {
        headers: { 'Content-Type': 'application/json' },
      })
      .toPromise();
  }

  async reserve(
    contractId: string,
    generationId: string,
    walletAddress: string,
    name: string,
    emailAddress: string
  ): Promise<void> {
    const data = {
      name,
      emailAddress,
      walletAddress,
    };
    return this.http
      .post<void>(`${environment.apiUrl}/contracts/${contractId}/generations/${generationId}/reserve`, data, {
        headers: { 'Content-Type': 'application/json' },
      })
      .toPromise();
  }

  async getReservationInvitation(contractId: string, invitationId: string): Promise<IReservationInvitation> {
    const invitation = await this.http
      .get<IReservationInvitation>(
        `${environment.apiUrl}/contracts/${contractId}/reservationinvitations/${invitationId}`
      )
      .toPromise();
    return invitation;
  }

  async reserveByInvitation(
    contractId: string,
    invitationId: string,
    walletAddress: string,
    name: string,
    emailAddress: string
  ): Promise<{ success: boolean; newInvitation: IReservationInvitation }> {
    const invitation = await this.getReservationInvitation(contractId, invitationId);
    if (invitation) {
      const data = {
        name,
        emailAddress,
        walletAddress,
        invitationId: invitation.Id,
      };
      return this.http
        .post<{ success: boolean; newInvitation: IReservationInvitation }>(
          `${environment.apiUrl}/contracts/${contractId}/reservationinvitations/${invitation.Id}/reserve`,
          data,
          { headers: { 'Content-Type': 'application/json' } }
        )
        .toPromise();
    } else {
      throw 'Could not find this invitation.';
    }
  }

  async customize(
    contractId: string,
    tokenName: string,
    walletAddress: string,
    name: string,
    emailAddress: string
  ): Promise<void> {
    const data = {
      name,
      emailAddress,
      walletAddress,
      tokenName,
    };
    return this.http
      .post<void>(`${environment.apiUrl}/contracts/${contractId}/messages/send`, data, {
        headers: { 'Content-Type': 'application/json' },
      })
      .toPromise();
  }

  async saveVoucher(contractId: string, voucher: string, notes: string): Promise<IVoucher> {
    return this.http
      .post<IVoucher>(`${environment.apiUrl}/contracts/${contractId}/vouchers`, {
        Voucher: voucher,
        ContractId: contractId,
        ExcludeRandom: false,
        Redeemed: false,
        Notes: notes,
      })
      .toPromise();
  }

  async saveContract(
    contractAbi: any,
    contractAddress: string,
    contractName: string,
    contractSymbol: string,
    contractDomain: string,
    domainVersion: number,
    voucherOptions: IVoucherOption[]
  ) {
    return this.http
      .post<IContract>(`${environment.apiUrl}/contracts`, {
        Address: contractAddress,
        ContractDomain: contractDomain,
        DomainVersion: domainVersion,
        ChainID: null,
        VoucherOptions: JSON.stringify(voucherOptions),
        Contract: contractAbi,
        Name: contractName,
        Symbol: contractSymbol,
      })
      .toPromise();
  }
}
