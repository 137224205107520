<header-component></header-component>

<div id="TeddyExplorersComponent">
  <h1 class="visually-hidden">Teddy Explorers</h1>
  <div class="container-fluid page-content">
    <!-- Row -->
    <div class="row mb-5">
      <div class="col-12 col-md-5 p-0">
        <div class="d-flex w-100 video-container">
          <app-inline-video [showing]="'teddy-explorer'"></app-inline-video>
        </div>
      </div>
      <div class="col-12 col-md-7 p-5 text-container">
        <h1>Minted Teddy Explorers</h1>
        <h2>Get your own teddy explorer to discover all the bounty across the Mintedverse</h2>
        <p class="reversed">
          Get ready to embark on a virtual exploration of the Mintedverse where you can train your teddy AI to mirror
          your likeness and have it become a significant part of your legacy.
        </p>
        <p class="reversed mb-4">
          Minted Teddy is a collaborative experience designed to capture the best of humanity to be passed on as smart
          teddy bears to future generations.
        </p>
        <button (click)="goToMint()" disabled>Mint</button>
      </div>
    </div>
    <!-- Row -->
    <div class="row adventure-awaits pb-5 mb-5">
      <div class="col-md-1"></div>
      <div class="col-12 col-md-6 p-5 text-container">
        <h1>The Collection</h1>
        <p class="reversed mb-4">
          2,500 unique digital teddies will be available to be minted. Each digital teddy comes with a beautifully
          crafted physical teddy toy too.
        </p>
        <h1>Adventure Awaits</h1>
        <p class="reversed mb-4">
          Your adventure starts when your physical toy arrives. Scan the toy with your NFC enabled smartphone to unlock
          the adventure and to start building your teddy AI.
        </p>
        <div class="d-flex flex-wrap align-items-center button-group">
          <button class="mb-3" (click)="goToMint()" disabled>Mint</button>
          <button class="mb-3 secondary" (click)="goToMintedverse()">Explore Mintedverse</button>
        </div>
      </div>
      <div class="col-12 col-md-5 p-0 d-flex justify-content-center justify-content-md-start">
        <app-inline-video [showing]="'teddy-portal'"></app-inline-video>
      </div>
    </div>
  </div>
</div>

<footer-component></footer-component>
