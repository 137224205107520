import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { IpfsService } from 'src/app/services/ipfs.service';
import { Web3Service } from 'src/app/services/web3.service';
import { environment } from 'src/environments/environment';
import TeddyTokens from '../../../assets/data/tokens.json';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IPreviewTokenMetadata, ITokenMetadata } from '../../interfaces/ITokenMetadata';

@Component({
  selector: 'app-teddy-gallery-mooncats',
  templateUrl: './teddy-gallery-mooncats.component.html',
  styleUrls: ['./teddy-gallery-mooncats.component.sass']
})
export class TeddyGalleryMoonCatsComponent implements OnInit, OnDestroy {
  // tokenData!: any;
  public avatarDisplay:string = 'pfp';
  public isMobile: boolean = false;
  catalogData!: any[];
  loaded: boolean = false;
  contractAddress!: string;
  sending: boolean = false;
  owned: boolean = false;
  public loading: boolean = true;
  num: number = 0;
  selectedTeddy: any;
  selectedMovie: any;
  // sets
  page: number = 1;
  totalPages: number = 1;
  totalTeddies: number = 1;
  setLength: number = 24;
  // start_num: number = 0;
  end_num: number = 24;
  // media query resize columns shown
  private mediaSubscription: any;
  private activeMediaQuery = '';
  cols: number = 4;
  rowHeight: number = 200;
  // forms
  paginationForm = new FormControl('');
  jumpToTeddy = new FormControl('');

  constructor(private ipfsService: IpfsService, private web3Service: Web3Service, private contractService: ContractsService, private _activatedRoute: ActivatedRoute, private mediaObserver: MediaObserver, private modalService: NgbModal) { }

  formatPreviewTeddyObject(token: IPreviewTokenMetadata) {
    const tokenObj: any = {
      metadata: {}
    }

    tokenObj.metadata.name = token.Name;
    // format image url
    tokenObj.metadata.image = token.ImageUrl?.replace('ipfs://', 'https://ipfs.mintedteddy.com/cdn-cgi/image/fit=scale-down,width=500/ipfs/') ?? '/assets/img/mintedteddy-golden-ticket.png';
    // format tokenId
    tokenObj.tokenId = token.TokenId;
    // format detailUrl
    tokenObj.detailUrl = '/catalog/' + tokenObj.tokenId + '/detail';
    // format openSeaUrl
    tokenObj.openSeaUrl = 'https://opensea.io/assets/0x69A04d373e3E2Db13027E9d584eB8203d46a86C7/' + tokenObj.tokenId;


    tokenObj.metadata.attributes = []

    for (const trait of token.PreviewMetadataTraits) {
      const currentTrait = { trait_type: '', value: '' }
      currentTrait.trait_type = trait.Trait.Name;
      currentTrait.value = trait.Value;
      tokenObj.metadata.attributes.push(currentTrait);
    }

    return tokenObj;
  }

  async getPage(page: number) {
    // Reset catalog data
    this.catalogData = [];

    const startIndex = (page * 24) - 24
    const endIndex = page * 24;

    const tokens = await this.contractService.getPreviewTokens("rinkeby", "mooncats");
    for (const token of tokens) {
      const tokenObj = this.formatPreviewTeddyObject(token);
      this.catalogData.push(tokenObj);
    }
  }

  // getDataSet() {
  //   // Reset catalog data
  //   this.catalogData = [];
  //   // Iterate through data set with num & end_num
  //   for (let i = this.num; i < this.end_num; i++) {
  //     let token = TeddyTokens[this.num];
  //     token = this.formatTeddyObject(token);
  //     this.catalogData.push(token);
  //     // Advance number
  //     this.num = this.num + 1;
  //   }
  //   // console.log(this.catalogData);
  // }

  ngOnInit(): void {
    console.log(window.location.pathname);
    this.loading = true;

    const ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.isMobile = true;
    }

    // Detect screen width
    this.mediaSubscription = this.mediaObserver
      .asObservable()
      .subscribe((change) => {
        change.forEach((item) => {
          this.activeMediaQuery = item
            ? `'${item.mqAlias}' = (${item.mediaQuery})`
            : '';
          if (item.mqAlias === 'xs') { this.cols = 1; }
          if (item.mqAlias === 'sm') { this.cols = 2; }
          if (item.mqAlias === 'md') { this.cols = 3; }
          if (item.mqAlias === 'lg') { this.cols = 4; }
          // console.log('activeMediaQuery', this.activeMediaQuery);
        });
      });

    // Determine pagination values
    this.paginationForm.setValue(this.page);
    this.totalTeddies = TeddyTokens.length;
    this.totalPages = Math.ceil(this.totalTeddies / this.setLength);

    this._activatedRoute.params.subscribe(async parameter => {
      this.getPage(this.page);

      // Get data set
      // this.getDataSet();
      this.loaded = true;

      // if (this.web3Service.accounts.length > 0) {
      //   const tokens = await selectedContract.methods.getTokenIds(this.web3Service.accounts[0]).call();
      //   for (let token of tokens) {
      //     if (token === parameter.tokenId) {
      //       this.owned = true;
      //       break;
      //     }
      //   }
      //   const token = await this.contractService.getToken(environment.contractId, parameter.tokenId);
      // }
      // const voucher = JSON.parse(token.Voucher);
      // const uri = voucher.uri;
      // const tokenResponse = await this.ipfsService.getMetadata(uri);
      // tokenResponse.image = tokenResponse.image.replace('ipfs://', environment.ipfsUrl + '/')
      // tokenResponse.opensea = `${environment.openSeaUrl}/assets/${this.contractAddress}/${token.TokenId}`;
      // tokenResponse.series = String(voucher.edition);
      // this.tokenData = tokenResponse;
    })
  }

  updateSelectedTeddy(direction: string) {
    // get current index
    let index = this.catalogData.findIndex(x => x.tokenId === this.selectedTeddy.tokenId);

    if (direction === 'prev') {
      if (index > 0) {
        this.selectedTeddy = this.catalogData[index - 1];
      } else {
        this.selectedTeddy = this.catalogData[0];
      }
    }
    if (direction === 'next') {
      if (index < 23) {
        this.selectedTeddy = this.catalogData[index + 1];
      } else {
        this.selectedTeddy = this.catalogData[23];
      }
    }
  }

  // Show teddy by clicking on teddy image
  seeDetails(teddycontent: any, item: any) {
    this.modalService.open(teddycontent);
    this.selectedTeddy = item;
    this.selectedMovie = this.selectedTeddy.metadata.image.replace('.png', '.mov');
    setTimeout(() => {
      const modal = document.querySelector('ngb-modal-window');
      if (modal) {
        modal.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    }, 500);

    document.onkeydown = event => {
      if (event) {
        // left arrow
        if (event.keyCode == 37) {
          this.updateSelectedTeddy('prev');
        }
        // right arrow
        else if (event.keyCode == 39) {
          this.updateSelectedTeddy('next');
        }
      }
    }
  }

  closeModal() {
    const modal = document.querySelector('ngb-modal-window');
    if (modal) {
      this.modalService.dismissAll();
      document.onkeydown = null;
    }
  }

  // Update data set by page input
  handlePageChange(event: any) {
    if (event.keyCode === 13) {
      let input_value = event.target.value;
      event.preventDefault();
      this.page = parseInt(input_value);
      this.num = input_value * this.setLength - this.setLength;
      // this.start_num = input_value * this.setLength - this.setLength;
      this.end_num = input_value * this.setLength;
      //this.getDataSet();
      this.getPage(this.page);
    }
  }

  // Handle pagination prev/next
  navPage(direction: string) {
    if (direction === 'prev') {
      if (this.page > 1) {
        this.page = this.page - 1;
      }
    } else {
      if (this.page < this.totalPages) {
        this.page = this.page + 1;
      }
    }
    this.num = this.page * this.setLength - this.setLength;
    this.end_num = this.page * this.setLength;
    this.paginationForm.setValue(this.page);
    this.getPage(this.page);
    //this.getDataSet();
  }

  // Show teddy by token id field
  async showTeddy(teddycontent: any, event: any) {
    // console.log(event.target.value)
    if (event.keyCode === 13) {
      event.preventDefault();

      const token = await this.contractService.getPreviewTokenById("rinkeby", "mooncats", event.target.value);
      const teddy = this.formatPreviewTeddyObject(token);

      if (teddy !== null) {
        this.selectedTeddy = teddy;
        this.selectedMovie = teddy.metadata.image.replace('.png','.mov');
      } else {
        this.selectedTeddy = null;
        this.selectedMovie = null;
      }
      this.modalService.open(teddycontent);
    }
  }

  ngOnDestroy(): void {
    this.mediaSubscription.unsubscribe();
  }

  goToOpenSea(token: any) {
    window.open(token.openSeaUrl, "_blank");
  }

  showPFP() {
    this.avatarDisplay = "pfp";
  }

  show3D() {
    this.avatarDisplay = "3d";
  }
}
