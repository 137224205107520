import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import Web3 from 'web3';
import { Web3Service } from '../services/web3.service';
import { WEB3 } from '../web3';

@Injectable({
  providedIn: 'root'
})
export class NoWeb3Guard implements CanActivate {
  constructor(private web3Service: Web3Service, private router: Router) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
      try {
        const accounts = await this.web3Service.getAccounts();
        if(accounts && accounts.length > 0) {
          this.router.navigateByUrl('/');
          return false;
        }
      } catch (ex) {
        this.router.navigateByUrl('/get');
        return false;
      }
      
    return true;
  }
  
}