<div id="LaunchpadComponent" class="portal-app">
  <img src="/assets/portal/header-text-3.png" class="heading-text" (click)="goToChooseTeddy()" />

  <div class="video-container">
    <div class="video-wrapper">
      <iframe src="https://player.vimeo.com/video/782825806?autoplay=1&loop=1&autopause=0&muted=1&background=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
    <img src="/assets/portal/gold-frame.png" />
  </div>

  <script src="https://player.vimeo.com/api/player.js"></script>

</div>
