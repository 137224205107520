import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-noaccess',
  templateUrl: './noaccess.component.html',
  styleUrls: ['./noaccess.component.sass']
})
export class NoaccessComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit(): void { }

  async goToCatalog() {
    await this.router.navigateByUrl('/catalog');
  }
}
