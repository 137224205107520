<h1>Theme Planet</h1>
<p class="bold highlight mb-4">🧸 This can be your planet! 🧸</p>
<p>
  Planets are mini clubs in the Mintedverse developed by planet guardians, in partnership with the Minted Teddy team.
  Each planet has a theme that supports our mission to create the most impactful teddy. For example, music planet will
  create music that uplifts and inspires using teddies as meta stars.
</p>

<p>
  Explorer teddies will able to visit planets as part of their mission to train themselves about the knowledge and
  wisdom we want to pass on to the next generation. This way, each planet in our community contributes to the
  development of the teddy character.
</p>

<div class="d-flex flex-wrap my-3 btn-container">
  <button class="my-2 mx-2" (click)="mintNow()" (tap)="mintNow()">Mint Coming Soon</button>
  <!-- <button class="secondary my-2 mx-2" disabled>Button 2</button> -->
</div>
